import { Component, OnInit } from '@angular/core';
import { DeepLinkType } from '@app/models/deep-link.model';
import { UrlService } from '../../../services/url.service';
import { v4 as uuidv4, v4 } from 'uuid';
import { FingerprintService } from '../../../services/fingerprint.service';
import { ActivatedRoute } from '@angular/router';
import {PushToCartComponent} from '@app/marketplace/shared/_components/push-cart.component';
import {StorageService} from '@app/services/storage.service';
declare let gtag: Function;
declare let fbq: Function;

export class TrackingComponent extends PushToCartComponent {

  ed: any;
  dti = '';
  deepLink = '';
  event = '';
  sid = v4();

  _urlService: UrlService;

  DeepLinkType = DeepLinkType;

  constructor(urlService: UrlService, fingerPrintService: FingerprintService, storageService: StorageService, route: ActivatedRoute, event: string) {
    super(null, null, null, null);
    this._urlService = urlService;
    fingerPrintService.visitorId.subscribe(visitorId => this.dti = visitorId);
    if (route.snapshot.queryParamMap.keys.length > 0 && route.snapshot.queryParamMap.keys.find(x => x.startsWith('utm_') || x.startsWith('source') || x == 'stu_code')) {
      const stu_code = route.snapshot.queryParamMap.keys.find(x => x == 'stu_code');
      if (stu_code && storageService) {
        storageService.setPromoCode({code: route.snapshot.queryParamMap.get(stu_code), expiration: Date.now()});
      }
      this.ed = route.snapshot.queryParams;
    }
    this.event = event;
  }

  callToAction(id: DeepLinkType, src: string, deepLink: string) {
    gtag('event', this.event);
    fbq('track', this.event);
    this.deepLink = deepLink;
    this._urlService.callToAction({ id, src, ed: this.ed, dti: this.dti, sid: this.sid })
      .then((result: any) => {
        this.deepLink = '';
        if (result && result.success == true) {
          window.location.href = result.url;
        } else {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.cubatel.android';
        }
      }).catch(() => {
        this.deepLink = '';
        window.location.href = 'https://play.google.com/store/apps/details?id=com.cubatel.android';
      });
  }
}
