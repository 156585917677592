import { User } from '@app/models/user.model';
import { createAction, props } from '@ngrx/store';

export const LOGIN = '[AUTH] Login';
export const LOGIN_SUCCESS = '[AUTH] Login Success';
export const LOGIN_FAILURE = '[AUTH] Login Failure';
export const SIGNUP = '[AUTH] Signup';
export const SIGNUP_SUCCESS = '[AUTH] Signup Success';
export const SIGNUP_FAILURE = '[AUTH] Signup Failure';
export const LOGOUT = '[AUTH] Logout';

export const LogIn  = createAction(
  LOGIN,
  props<{payload:any}>()
);
export const LogInSuccess  = createAction(
  LOGIN_SUCCESS,
  props<{payload:User}>()
);
export const LogInFailure  = createAction(LOGIN_FAILURE);
export const Logout  = createAction(LOGOUT);
export const SignUp = createAction(SIGNUP);
export const SignUpSuccess   = createAction(SIGNUP_SUCCESS);
export const SignUpFailure   = createAction(SIGNUP_FAILURE);

