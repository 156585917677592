import { Component, OnInit, Inject, OnDestroy, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { LazyLoad } from '@app/helpers/lazyload';
import { DOCUMENT } from '@angular/common';
import { LoginService } from '@app/services/login.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPhoneVerificationCodeComponent } from '../modal-phone-verification-code/modal-phone-verification-code.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal-phone-verification',
  templateUrl: './modal-phone-verification.component.html',
  styleUrls: ['./modal-phone-verification.component.css']
})
export class ModalPhoneVerificationComponent implements OnInit, OnDestroy {

  @Input()
  access_token: string;
  @Input()
  isoCode: string;

  phoneControl: FormControl;

  model: any;

  waitTime = 0;
  interval: any;

  phoneVerification = true;
  verificationCode = false;

  loadingChange$: Observable<boolean>;

  constructor(
    private loginService: LoginService,
    private toastr: ToastrService,
    private activeModal: NgbActiveModal,
    @Inject(DOCUMENT) private document: Document
  ) {

    this.model = {
      action: 0,
      phoneNumber: '',
      attempt: 1
    };
    this.isoCode = 'us';
  }
  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  ngOnInit() {
    this.loadingChange$ = this.loginService.loadingChange$;
    LazyLoad.loadStyle(`intlTelInput`, this.document);
    this.phoneControl = new FormControl('', [Validators.required]);
  }

  sendPhone() {
    if (!this.phoneControl.invalid && !this.phoneControl.pristine) {
      this.model.phoneNumber = this.phoneControl.value.internationalNumber;
      this.loginService.phoneVerification(this.model, this.access_token)
        .then(response => {
          if (response.attempt == 0) {
            this.model.attempt = 1;
            this.waitTime = response.waitTime;
            this.countDown();
            this.toastr.error(response.errorTitle + ' ' + response.errorMessage);
          } else {
            this.model.attempt = response.attempt + 1;
            if (response.success) {
              if (response.phoneNumberConfirmed) {
                this.activeModal.close({ completed: true });
              } else {
                this.phoneVerification = false;
                this.verificationCode = true;

              }
            } else {
              this.toastr.error(response.errorTitle + ' ' + response.errorMessage);
            }
          }
        }).catch(error => {
          this.activeModal.close({ error: true });
        });
    } else {
      this.toastr.error('Your mobile number is invalid');
    }

  }

  countDown() {
    this.interval = setInterval(() => {
      if (this.waitTime != 0) {
        this.waitTime--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  convertToDate() {
    return new Date(this.waitTime * 1000).toISOString().substr(11, 8);
  }

  verificationCodeResponse(response: any) {
    if (!response.error) {
      if (response.completed) {
        this.activeModal.close({ completed: true });
      } else {
        this.activeModal.close({ completed: false });
      }
    } else {
      this.activeModal.close({ error: true });
    }
  }

  editNumber() {
    this.phoneControl = new FormControl(this.phoneControl.value.number, [Validators.required]);
    this.verificationCode = false;
    this.phoneVerification = true;

  }

}
