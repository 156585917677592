export class TermsAnsConditionsModel {
    termsAndConditionsApproved: boolean;
    emailNotificationsAllowed: boolean;
    smsNotificationsAllowed: boolean;

    constructor(terms?) {
        terms = terms || {};
        this.termsAndConditionsApproved = terms.termsAndConditionsApproved;
        this.emailNotificationsAllowed = terms.emailNotificationsAllowed;
        this.emailNotificationsAllowed = terms.emailNotificationsAllowed;
    }
}

