import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SharedService } from "./shared.service";

@Injectable({
  providedIn: 'root'
})
export class TrackingService extends SharedService {

  constructor(
    httpCLient: HttpClient
  ) {
    super(httpCLient);
  }

  notificationDat(dat: any) {
    const url = 'api/Notification/Dat';
    return this.postItmes(url, dat);
  }
}
