import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from './shared.service';
import { BehaviorSubject } from 'rxjs';
import { PromotionDetailsModel } from '../models/interfaces/promotion-details.model';

@Injectable({
  providedIn: 'root'
})
export class PromotionsService extends SharedService {

  flashOfferSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  flashOffer$ = this.flashOfferSubject.asObservable();

  constructor(
    httpCLient: HttpClient
  ) {
    super(httpCLient);
  }

  getPromotionDetails() {
    const url = 'api/TopUp/GetPromotionDetails';
    return this.getItems(url);
  }

  getCashBack() {
    const url = 'api/Reward/GetCashback';
    return this.getItems(url);
  }
}
