import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { UrlService } from '@app/services/url.service';
import { environment } from '@env/environment.prod';
import { DeepLinkModel, DeepLinkType } from '../models/deep-link.model';
import { FingerprintService } from '../services/fingerprint.service';
import { v4 as uuidv4, v4 } from 'uuid';
import { TrackingComponent } from '../shared/components/tracking/tracking.component';
declare let gtag: Function;
declare let fbq: Function;
declare let $zoho: any;

@Component({
  selector: 'app-remittance-seo',
  templateUrl: './remittance-seo.component.html',
  styleUrls: ['./remittance-seo.component.css']
})
export class RemittanceSeoComponent extends TrackingComponent implements OnInit {

  title = 'Envío de dinero a Cuba al instante - Cubatel';
  description = 'Remesas a Cuba ✔️ Fácil ✔️ Seguro y ✔️ Rápido. Envía DINERO a tus familiares y amigos en Cuba【 Sin Intermidiarios】>> ¡Descarga la App HOY!';
  keywords = 'envío de dinero a cuba, envios de dinero a cuba, envio de dinero cuba, remesas a cuba';

  DeepLinkType = DeepLinkType;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private urlService: UrlService,
    private titleService: Title,
    private metaService: Meta,
    private fingerprintService: FingerprintService,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    super(urlService, fingerprintService, null, route, 'stu_btn_remittance');
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
    this.metaService.updateTag({
      name: 'description',
      content: this.description,
    });
    this.metaService.updateTag({ name: 'keywords', content: this.keywords });
    this.metaService.updateTag({ name: 'robots', content: 'all' });
    this.metaService.updateTag({ property: 'og:title', content: this.title });
    this.metaService.updateTag({
      property: 'og:description',
      content: this.description,
    });
    this.metaService.updateTag({
      property: 'og:url',
      content: `${environment.host}${this.router.url}`,
    });
    this.titleService.setTitle(this.title);
    this.urlService.changeUrl(this.router.url);
    this.urlService.footer.emit();
  }

  startChat() {
    $zoho.salesiq.chat.start();
  }
}
