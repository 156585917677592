import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalDeleteConfirmationComponent } from './components/modal-delete-confirmation/modal-delete-confirmation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { RouterModule } from '@angular/router';
import { ModalDiscountConfirmationComponent } from './components/modal-discount-confirmation/modal-discount-confirmation.component';
import { NgbModalModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { StuSelectComponent } from './components/stu-select/stu-select.component';
import { StuSelectServiceService } from './components/stu-select/stu-select.service';
import { ModalPhoneVerificationComponent } from './components/modal-phone-verification/modal-phone-verification.component';
import { ModalPhoneVerificationCodeComponent } from './components/modal-phone-verification-code/modal-phone-verification-code.component';
import { ModalClaimPhoneNumberComponent } from './components/modal-claim-phone-number/modal-claim-phone-number.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ModalEligibleDiscountComponent } from './components/modal-eligible-discount/modal-eligible-discount.component';
import { ModalEligibleCashbackComponent } from './components/modal-eligible-cashback/modal-eligible-cashback.component';
import { ModalCvvInfoComponent } from './components/modal-cvv-info/modal-cvv-info.component';
import { ModalCouponInfoComponent } from './components/modal-coupon-info/modal-coupon-info.component';
import { StuTypeaheadContactComponent } from './components/stu-typeahead-contact/stu-typeahead-contact.component';

@NgModule({
  declarations: [
    ModalDeleteConfirmationComponent,
    ModalConfirmationComponent,
    ModalDiscountConfirmationComponent,
    StuSelectComponent,
    ModalPhoneVerificationComponent,
    ModalPhoneVerificationCodeComponent,
    ModalClaimPhoneNumberComponent,
    ModalEligibleDiscountComponent,
    ModalEligibleCashbackComponent,
    ModalCvvInfoComponent,
    ModalCouponInfoComponent,
    StuTypeaheadContactComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxIntlTelInputModule,
    NgbTypeaheadModule,
    NgbModalModule
  ],
  exports: [
    ModalDeleteConfirmationComponent,
    ModalConfirmationComponent,
    ModalDiscountConfirmationComponent,
    ModalCouponInfoComponent,
    StuSelectComponent,
    StuTypeaheadContactComponent
  ],
  providers: [
    StuSelectServiceService,
  ]
})
export class SharedModule { }
