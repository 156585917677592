import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { DataService } from "@app/services/data.service";
import { map } from "rxjs/operators";
import { isObject } from "util";

@Injectable()
export class RedirectWithParamsGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot) {
    const path = route.data?.redirectTo || '';
    if (route.queryParams.stu_code || route.queryParamMap.keys.find(x => x.startsWith('utm_'))) {
      this.router.navigate([`/${path}`], { queryParams: route.queryParams});
    } else {
      this.router.navigate(['/']);
    }
    return false;
  }
}
