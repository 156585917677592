<div id="expired-session" class="modal-header pt-5 pb-4">
  <img width="100" src="assets/icon-sesion.svg"
    alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba" />
</div>


<div class="modal-body pt-2">

  <p class="text-center"> Lo sentimos. Su sesión expiró.<br> Vuelva a iniciar sesión</p>


</div>
<div class="modal-footer justify-content-center pt-0 pb-4">
  <button type="button" class="btn btn-primary banner-button modal-banner-button" ngbAutofocus
    (click)="activeModal.close()">ACEPTAR</button>

</div>