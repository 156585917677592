import {
  ApplicationRef,
  Compiler,
  Component,
  Injector,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '../../services/storage.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.css'],
})
export class ModalAuthComponent implements OnInit {
  @ViewChild('authentication', { static: true, read: ViewContainerRef })
  authentication: ViewContainerRef;

  private _componentRef;

  @Input()
  returnUrl: string;

  loading: Observable<boolean>;

  constructor(
    public activeModal: NgbActiveModal,
    private storageService: StorageService,
    private _injector: Injector,
    private _viewRef: ViewContainerRef,
    private _renderer: Renderer2,
    private _compiler: Compiler,
    private _applicationRef: ApplicationRef
  ) { }

  ngOnInit() {
    this.loading = this.storageService.loadLogin;
    this.loadModule();
  }

  loadModule() {
    import('@app/menu/authentication/authentication.module')
      .then((module) => module[Object.keys(module)[0]])
      .then((module) => this._compiler.compileModuleAsync(module))
      .then((factory) => {
        const moduleRef = factory.create(this._injector);
        const component = (moduleRef as any)._bootstrapComponents[0];
        this._componentRef = moduleRef.componentFactoryResolver.resolveComponentFactory(component).create(this._injector, [], null, moduleRef);
        // Set component input variables
        this.storageService.loadLoginSubject.next(false);
        this._componentRef.instance.returnUrl = this.returnUrl;
        this._applicationRef.attachView(this._componentRef.hostView);
        this._applicationRef.attachView(this._componentRef.hostView);
        this._renderer.appendChild(this._viewRef.element.nativeElement, this._componentRef.location.nativeElement);
      });
  }

}
