import {Injectable} from '@angular/core';
import {SharedService} from './shared.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import { AppNotificationDisplay, AppNotification } from '@app/models/stu-notification';

const httpOptions = {
  headers: new HttpHeaders({'Accept-Language': 'es'})
};

@Injectable({
  providedIn: 'root'
})
export class HtmlContentService extends SharedService {

  contentPromotionSubject = new BehaviorSubject<any>({});
  contentPromotion: Observable<any> = this.contentPromotionSubject.asObservable();

  contentNotificationSubject = new BehaviorSubject<any>(null);
  contentNotification: Observable<boolean> = this.contentNotificationSubject.asObservable();

  constructor(
    httpClient: HttpClient,
  ) {
    super(httpClient);
  }

  GetHtmlContentSearch(filter: string) {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [1, 3], Term: filter});
  }

  GetHtmlContentFaqGeneral() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [1], Term: '', ContentSubtypes: [1], UserAgent: 1});
  }

  GetHtmlContentFaqRecharge() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [1], Term: '', ContentSubtypes: [3], UserAgent: 1});
  }

  GetHtmlContentFaqSms() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [1], Term: '', ContentSubtypes: [5], UserAgent: 1});
  }

  GetHtmlContentFaqNauta() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [1], Term: '', ContentSubtypes: [4], UserAgent: 1});
  }

  GetHtmlContentFaqCall() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [1], Term: '', ContentSubtypes: [2], UserAgent: 1});
  }

  GetHtmlContentFaqRetail() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [1], Term: '', ContentSubtypes: [6], UserAgent: 1});

  }

  GetHtmlContentFaqApp() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [1], Term: '', ContentSubtypes: [7], UserAgent: 1});
  }

  GetHtmlContentHowToRecharge() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [3], Term: '', ContentSubtypes: [3], UserAgent: 1});
  }

  GetHtmlContentHowToSms() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [3], Term: '', ContentSubtypes: [5], UserAgent: 1});
  }

  GetHtmlContentHowToNauta() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [3], Term: '', ContentSubtypes: [4], UserAgent: 1});
  }

  GetHtmlContentHowToCall() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [3], Term: '', ContentSubtypes: [2], UserAgent: 1});
  }

  GetHtmlContentHowToRetail() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [3], Term: '', ContentSubtypes: [6], UserAgent: 1});
  }

  GetHtmlContentHowToApp() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [3], Term: '', ContentSubtypes: [7], UserAgent: 1});
  }

  GetHtmlContentPromotion() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [5], Term: '', UserAgent: 1});
  }

  GetHtmlContentTermsAndConditions() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [2], Term: '', UserAgent: 1});
  }

  GetHtmlContentPrivacyPolicy() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [7], Term: '', UserAgent: 1});
  }

  GetHtmlContentTermsAndConditionsRemittance() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [9], Term: '', UserAgent: 1});
  }

  GetHtmlContentPrivacyPolitics() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [10], Term: '', UserAgent: 1});
  }

  GetHtmlContentTutorial() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [8], Term: '', UserAgent: 1});
  }

  GetHtmlContentTestimonials() {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [4], Term: '', UserAgent: 1});
  }

  GetNotifications() {
    const url = 'api/Notification/GetNotifications';
    return this.postItmes(url, {Platform: 1, NotificationType: [2, 8]});
  }

  GetNotification(type: number) {
    const url = 'api/Notification/GetNotifications';
    return this.postItmes(url, {Platform: 1, NotificationType: [type]});
  }

  GetArticles(slug: string = '') {
    const url = 'api/content/get';
    return this.postItmes(url, {ContentTypes: [6], ContentSubtypes: [1], Term: '', Slug: slug, UserAgent: 1});
  }

}
