import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '@app/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  userSubhject: BehaviorSubject<User> = new BehaviorSubject<any>(null);
  user = this.userSubhject.asObservable();

  resetFormSubjec: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  resetForm = this.resetFormSubjec.asObservable();

  usernameSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  username = this.usernameSubject.asObservable();

  constructor() { }

  setUser(user: User) {
    this.userSubhject.next(user);
  }

  setResetForm(reset: boolean) {
    this.resetFormSubjec.next(reset);
  }

  setUsername(username: string) {
    this.usernameSubject.next(username);
  }

}
