<footer class="d-flex flex-column">
  <div id="f-links" class="py-5 px-xl-5 justify-content-xxl-center">
    <div class="d-flex flex-column text-center text-lg-left mx-xxl-5 px-xxl-4">
      <span class="font-weight-bold pb-2 pb-md-3 link-h">Empresa</span>
      <span class="py-2 text-decoration-none cursor-pointer link" (click)="goToLink('/quienes-somos')"
        aria-label="About Us">¿Quiénes somos?</span>
      <span class="py-2 text-decoration-none cursor-pointer link"
        (click)="goToLink('https://www.cubatel.com/blog', true)" aria-label="Blog">Blog</span>
      <span class="py-2 text-decoration-none cursor-pointer link" (click)="goToLink('/mayorista')"
        aria-label="Mayorista">Trabaja con
        Cubatel</span>
    </div>
    <div class="d-flex flex-column text-center text-lg-left mt-4 mt-md-0 mx-xxl-5 px-xxl-4">
      <span class="font-weight-bold pb-2 pb-md-3 link-h">Ayuda</span>
      <span class="py-2 text-decoration-none cursor-pointer link" (click)="goToLink('/asistencia')"
        aria-label="About Us">Preguntas
        frecuentes</span>
      <span class="py-2 text-decoration-none cursor-pointer link" (click)="goToLink('/contactanos')" rel="noreferrer"
        target="_blank" aria-label="Blog">Contáctanos</span>
    </div>
    <div class="d-flex flex-column text-center text-lg-left mt-4 mt-md-0 mx-xxl-5 px-xxl-4">
      <span class="font-weight-bold pb-2 pb-md-3 link-h">Legal</span>
      <ng-container *ngIf="!isRemittancePage">
        <span class="py-2 text-decoration-none cursor-pointer link"
              (click)="goToLink('/terminos')" aria-label="About Us">Términos y condiciones</span>
        <span class="py-2 text-decoration-none cursor-pointer link"
              (click)="goToLink('/politica-de-privacidad')" aria-label="About Us">Política de privacidad</span>
      </ng-container>
      <ng-container *ngIf="isRemittancePage">
        <span class="py-2 text-decoration-none cursor-pointer link"
              (click)="goToLink('/terminos-remesas')" aria-label="Términos y condiciones">Términos y condiciones</span>
        <span class="py-2 text-decoration-none cursor-pointer link"
              (click)="goToLink('/privacidad')" aria-label="Privacidad">Política de privacidad</span>
      </ng-container>

    </div>
    <div id="f-links-apps" class="d-flex flex-column mt-4 mt-lg-0 mx-xxl-5 px-xxl-4">
      <span class="font-weight-bold pb-4 d-none d-lg-flex link-h">Descarga nuestra App</span>
      <a class="btn btn-secondary bg-transparent py-2 my-1 text-decoration-none"
        href="https://play.google.com/store/apps/details?id=com.cubatel.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        rel="noreferrer" target="_blank" aria-label="Google Play">
        <picture>
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/footer/google-play-1.webp" type="image/webp" />
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/footer/google-play-1.png" type="image/png" />
          <img class="lazyload" width="140" height="38"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/footer/google-play-1.png" alt="Google Play" />
        </picture>
      </a>
      <a class="btn btn-secondary bg-transparent py-2 my-3 text-decoration-none"
        href="https://itunes.apple.com/us/app/cubatel/id1300396049" rel="noreferrer" target="_blank"
        aria-label="App Store">
        <picture>
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/footer/app-store-1.webp" type="image/webp" />
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/footer/app-store-1.png" type="image/png" />
          <img class="lazyload" width="140" height="38"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/footer/app-store-1.png" alt="App Store" />
        </picture>
      </a>
    </div>
  </div>
  <!-- RECARGATON-->
  <div *ngIf="(flashOfferEnabled | async) && mainPages" id="recargaton"
    class="stu-bg-primary d-flex flex-column flex-md-row justify-content-center align-items-center py-3 px-3">
    <p class="text-white text-center text-md-left mb-3 mb-md-0">Código promocional: <span
        class="font-weight-bold text-white">RECARGATON21</span> recarga por solo <span
        class="font-weight-bold text-white">$19.59 USD</span></p>
    <button [disabled]="deepLink == 'recharge'"
      (click)="callToAction(DeepLinkType.Recharges, 'recharge-offer', 'recharge')"
      class=" btn btn-primary  bg-transparent text-white font-weight-bold ml-3 rounded-pill position-relative"
      aria-label="Recargar">Recarga Aquí
      <ng-container *ngIf="deepLink != 'recharge'; else snippet"></ng-container></button>
  </div>
  <!-- END RECARGATON-->
  <div id="f-social-networks" class="d-flex flex-column justify-content-center align-items-center pt-5 pb-4">
    <div class="text-center pb-4">
      <span (click)="goToLink('https://www.instagram.com/cubateloficial/', true)" class="instagram cursor-pointer"
        aria-label="Instagram"><img class="lazyload" width="55.27" height="55.27" alt="Instagram"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/footer/instagram-1.svg"></span>
      <span (click)="goToLink('https://www.facebook.com/cubateloficial', true)" class=" facebook mx-3 cursor-pointer"
        aria-label="Facebook"><img class="lazyload" width="55.27" height="55.27" alt="Facebook"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/footer/facebook-1.svg"></span>
      <span (click)="goToLink('https://www.youtube.com/channel/UC0HcpYElH8p_9DFF6OmfCrw', true)"
        class="youtube cursor-pointer" aria-label="Youtube"><img class="lazyload" width="55.27" height="55.27"
          alt="Youtube" data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/footer/youtube-1.svg"></span>
      <span (click)="goToLink('https://t.me/s/cubateloficial?before=64', true)" class="telegram ml-3 cursor-pointer"
        aria-label="Telegram"><img class="lazyload" width="55.27" height="55.27" alt="Telegram"
          data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/footer/telegram-1.svg"></span>
    </div>
    <span class="small Poppins text-center">Copyright© Cubatel {{currentYear}}. </span>
  </div>
</footer>
<ng-template #snippet let-css>
  <div class="backdrop" [style]="css || 'border-radius: 25px'">
    <div class="snippet" data-title="dot-pulse">
      <div class="stage">
        <div class="dot-pulse"></div>
      </div>
    </div>
  </div>
</ng-template>
