import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RecommendationModel } from '@app/models/recommendation.model';
import { SharedService } from './shared.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { RewardModel } from '@app/models/reward.model';

@Injectable({
  providedIn: 'root'
})
export class BonusService extends SharedService {

  rewardsSumarySubject: BehaviorSubject<RewardModel>;
  rewardsSummary: Observable<RewardModel>;

  constructor(
    httpCLient: HttpClient
  ) {
    super(httpCLient);
    this.rewardsSumarySubject = new BehaviorSubject<RewardModel>({
      availableCashback: 0,
      rewardsEarned: 0,
      cashback: [],
      discounts: [],
      success: false
    });
    this.rewardsSummary = this.rewardsSumarySubject.asObservable();
  }

  sendRecommendation(recommendation: RecommendationModel) {
    const url = 'api/Bonus/SendRecommendation';
    return this.postItmes(url, recommendation);
  }

  getRewards(userLevel: number = 1) {
    if (userLevel == 1) {
      const url = 'api/Reward/GetRewards';
      return this.getItems(url);
    }
    return new Promise<any>((resolve, reject) => { resolve({ availableCashback: 0 }); });
  }
}
