import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TermsAnsConditionsModel } from '@app/models/terms-and-conditions.model';
import { environment } from '@env/environment';
const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService extends SharedService {

  constructor(private http: HttpClient) {
    super(http);
  }

  getTermsAndConditions(access_token: string) {
    const url = 'api/TopUp/GetNotificationSettings';
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${access_token}`, 'Accept-Language': 'es', 'Stu-App-Version': '2.0.0' })
    };
    return this.getItemsWithCustomHeader(url, httpOptions);
  }

  setTermsAndConditions(terms: TermsAnsConditionsModel, access_token: string) {
    const url = 'api/TopUp/SetNotificationSettings';
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${access_token}`, 'Accept-Language': 'es', 'Stu-App-Version': '2.0.0' })
    };
    return this.postItmesWithCustomHeader(url, terms, httpOptions);
  }

  getItemsWithCustomHeader(url: string, httpOptions: any): Promise<any> {
    this.loadingSubject.next(true);
    return new Promise((resolve, reject) => {
      this._httpClient.get(baseUrl + url, httpOptions)
        .subscribe((response: any) => {
          this.loadingSubject.next(false);
          resolve(response);
        }, (reason: any) => {
          this.loadingSubject.next(false);
          reject(reason);
        });
    });
  }

  postItmesWithCustomHeader(url: string, items: any, httpOptions: any): Promise<any> {
    this.loadingChangeSubject.next(true);
    return new Promise((resolve, reject) => {
      this._httpClient.post(baseUrl + url, items, httpOptions)
        .subscribe((response: any) => {
          this.loadingChangeSubject.next(false);
          resolve(response);
        }, (reason: any) => {
          this.loadingChangeSubject.next(false);
          reject(reason);
        });
    });
  }
}
