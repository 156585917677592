import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {RechargeModel} from '@app/models/recharge.model';

@Injectable({
  providedIn: 'root'
})
export class FingerprintService {

  visitorIdSubject = new BehaviorSubject<string>('');
  visitorId = this.visitorIdSubject.asObservable();

  constructor() { }

  setVisitorId(visitorId: string) {
    this.visitorIdSubject.next(visitorId);
  }

}
