import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalQuestionCashbackComponent } from './modal-question-cashback.component';

@NgModule({
  declarations: [ModalQuestionCashbackComponent],
  imports: [
    CommonModule,
    NgbModalModule,
  ],
  exports: [
      ModalQuestionCashbackComponent
  ]
})
export class QuestionCashbackModalModule { }
