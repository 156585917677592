
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { sha256 } from 'js-sha256';
import { environment } from 'src/environments/environment.dev';
import {Router} from '@angular/router';
import {StorageService} from '@app/services/storage.service';

@Injectable()
export class SignatureInterceptor implements HttpInterceptor {
  constructor(private router: Router, private storageService: StorageService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /* if (this.router.url.includes('market') && !this.storageService.isAuthenticated() && request.url.includes(environment.apiUrl)) */
    if (!this.storageService.isAuthenticated())
      request = request.clone({
          setHeaders: {
            "X-Signature": `${this.getSignature(request.body)}`,
            "Accept-Language": environment.locale,
            "Stu-App-Version": '2.0.0'
          }
        }
      );
    return next.handle(request);
  }
  getSignature(payload:any) {
    var message =JSON.stringify(payload);
    var hash = sha256.hmac.create(environment.signature_key);
    hash.update(message);
    var signature= hash.hex();
    return signature;
  }
}

