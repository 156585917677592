<div *ngIf="verificationCode">
  <div class="modal-header pt-4">
    <h2 class="modal-title text-center">Código de verificación </h2>
  </div>


  <div class="modal-body pt-2 d-flex flex-column align-items-center">

    <p class="text-center text-muted mb-1">Por favor, ingrese el código de verificación que enviamos al <span class="bold">{{visiblePhone}}</span>
    </p>
    <p class="edit-num"  (click)="verificationPhone()">Editar Número</p>
    <div class="w-100">
      <input class="form-control" aria-label="Código de verificación" type="text" [formControl]="codeControl"
        placeholder="Código" maxlength="6">
    </div>
    <div *ngIf="codeControl.errors && !codeControl.pristine">
      <div *ngIf="codeControl.errors.required" class="text-danger error">
        El código es
        requerido
      </div>
      <div *ngIf="codeControl.errors.invalidCodeVerification && !codeControl.errors.required" class="text-danger error">
        El código no es
        válido
      </div>
    </div>

  </div>
  <div class="modal-footer d-flex flex-column align-items-center">
    <button type="button" [disabled]="codeControl.invalid || codeControl.pristine"
      class="btn btn-primary banner-button modal-banner-button mb-2" (click)="sendCode()">Confirmar</button>
    <button *ngIf="waitTime == 0" type="button" class="btn btn-default banner-button modal-banner-button"
      (click)="sendNewCode()">Reenviar</button>
    <p *ngIf="waitTime != 0">Tiempo restante: <span class="bold">{{ convertToDate()}}</span>
    <p>
    <p class="text-muted text-center mt-3">El código expira en 10 minutos
    <p>
  </div>
  <div *ngIf="loadingChange$ | async" class="backdrop" style="border-radius: 8px">
    <div class="text-center">
      <picture>
        <source srcset="assets/loading.webp" type="image/webp">
        <source srcset="assets/loading.gif" type="image/gif">
        <img style="height: 70px; width:70px" src="assets/loading.gif" alt="Cargando...">
      </picture>
    </div>
  </div>
</div>
<app-modal-claim-phone-number *ngIf="claimNumberPhone" [phoneNumber]="phoneNumber" [access_token]="access_token"
  (response)="claimNumberPhoneResponse($event)"></app-modal-claim-phone-number>