import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {environment} from '@env/environment';

@Component({
  selector: 'app-download-app',
  templateUrl: './download-app.component.html',
  styleUrls: ['./download-app.component.css']
})
export class DownloadAppComponent implements OnInit {
  @Input()
  device: string;

  googlePlayPath = environment.googlePlayUrl;
  appleStorePath = environment.appleStoreUrl;

  constructor(public activeModal: NgbActiveModal) { }

  @Input()
  title: string;

  ngOnInit() {
  }

  onCancel() {
    this.activeModal.close();
  }

}
