<div class="modal-header pt-4">
  <h5 class="modal-title ">Cubatel Bono de Reembolso</h5>
</div>


<div class="modal-body pt-2">
  <p class=" w-100 text-justify ">
    - Actualmente tiene disponible ${{availableCashback}} USD de reembolso. Es posible que el balance actual no refleje
    las últimas transacciones completadas, el proceso de actualización puede tardar unos minutos en finalizar.
  </p>
  <p class=" w-100 text-justify ">
    - Puede aplicar su reembolso disponibe como crédito de descuento durante el pago de cualquier compra de recarga de
    Cubacel o Nauta.
  </p>
  <p class=" w-100 text-justify ">
    - Obtendrá hasta un 5% de reembolso en cada recarga completada de Cubacel o Nauta. El monto de las recompensas de
    reembolso puede cambiar en cualquier momento sin previo aviso.
  </p>
  <p class=" w-100 text-justify ">
    - El reembolso es acumulable y puede ser utilizado en cualquier momento, ¡no caducará!
  </p>
  <p class=" w-100 text-justify ">
    - Los pagos realizados con su saldo de devolución de efectivo son finales y no pueden cancelarse ni modificarse
    después de que se complete la recarga.
  </p>
  <p class=" w-100 text-justify ">
    - Si tiene alguna pregunta, inquietud o necesita más detalles sobre su saldo de devolución de efectivo, comuníquese
    con nuestro equipo de atención al cliente al <a href="tel:+1 (833)-282-2835">+1
      (833)-282-2835</a> / (883) -CUBATEL o envíanos un correo electrónico a <a
      href="mailto:soporte@cubatel.com">soporte@cubatel.com</a>
  </p>


</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-primary banner-button modal-banner-button" ngbAutofocus
    (click)="activeModal.close();">CERRAR</button>

</div>