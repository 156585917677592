import { isObject } from 'util';

export class CustomValidator {
  // Validates CUB phone numbers
  static phoneValidator(number): any {
    if (number.value === '') {
      return null;
    }
    if (number.pristine) {
      return null;
    }
    const PHONE_REGEXP = /^(5|6)[0-9]{7}$/;
    number.markAsTouched();
    if (isObject(number.value)) {
      if (PHONE_REGEXP.test(number.value.account.slice(3))) {
        return null;
      }
    } else {
      if (PHONE_REGEXP.test(number.value)) {
        return null;
      }
    }

    return {
      invalidNumber: true
    };
  }

  static phoneFullValidator(number): any {
    if (number.value === '') {
      return null;
    }
    if (number.pristine) {
      return null;
    }
    const PHONE_REGEXP = /^[0-9]+$/;
    number.markAsTouched();
    if (PHONE_REGEXP.test(number.value)) {
      return null;
    }
    return {
      invalidNumber: true
    };
  }

  static codeVerificationValidator(number): any {
    if (number.value === '') {
      return null;
    }
    if (number.pristine) {
      return null;
    }
    const CODE_VERIFICATION_REGEXP = /^[0-9]{6}$/;
    number.markAsTouched();
    if (CODE_VERIFICATION_REGEXP.test(number.value)) {
      return null;
    }
    return {
      invalidCodeVerification: true
    };
  }

  static nautaAccountaValidator(nauta): any {
    if (nauta.value === '') {
      return null;
    }
    if (nauta.pristine) {
      return null;
    }

    const NAUTA_REGEXP = /^[a-zA-Z0-9._-]+(@nauta.com.cu)$/;
    if (isObject(nauta.value)) {
      if (NAUTA_REGEXP.test(nauta.value.nautaAccount)) {
        return null;
      }
    } else {
      if (NAUTA_REGEXP.test(nauta.value)) {
        return null;
      }
    }

    return {
      invalidNauta: true
    };
  }

  static verifyCode(code): any {
    if (code.value === '') {
      return null;
    }
    if (code.pristine) {
      return null;
    }

    const CODE_REGEXP = /^[0-9]{2}$/;
    if (CODE_REGEXP.test(code.value)) {
      return null;
    }

    return {
      invalidCode: true
    };
  }

  static firstSixDigits(digits): any {
    if (digits.value === '') {
      return null;
    }
    if (digits.pristine) {
      return null;
    }

    const DIGITS_REGEXP = /^[0-9]{6}$/;
    if (DIGITS_REGEXP.test(digits.value)) {
      return null;
    }

    return {
      invalidFirstSixDigits: true
    };
  }

  static cvv(cvv): any {
    if (cvv.value === '') {
      return null;
    }
    if (cvv.pristine) {
      return null;
    }

    const CVV_REGEXP = /^[0-9]{3,4}$/;
    if (CVV_REGEXP.test(cvv.value)) {
      return null;
    }

    return {
      invalidCVV: true
    };
  }

  static zipCode(zipCode): any {
    if (zipCode.value === '') {
      return null;
    }
    if (zipCode.pristine) {
      return null;
    }

    const ZIPCODE_REGEXP = /^((\d+|[a-zA-Z]+)+([- ]){0,1}(\d+|[a-zA-Z]+)+)+$/;
    if (ZIPCODE_REGEXP.test(zipCode.value)) {
      return null;
    }

    return {
      invalidZipCode: true
    };
  }

  static cardNumber(cardNumber): any {
    if (cardNumber.value === '') {
      return null;
    }
    if (cardNumber.pristine) {
      return null;
    }

    //const CARD_NUMBER_REGEXP = /^[0-9]{16}$/;
    const visaExpression = /^4[0-9]{12}(?:[0-9]{3})?/;
    const mastercardExpression = /^5[1-5][0-9]{14}/;
    const amexExpression = /^3[47][0-9]{13}/;
    const discoverExpression = /^6(?:011|5[0-9]{2})[0-9]{12}/;
    const dinersClubExpression = /^3(?:0[0-5]|[68][0-9])[0-9]{11}/;
    const maestroExpression = /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}/;
    const unionPayExpression = /^(62[0-9]{14,17})/;
    if (visaExpression.test(cardNumber.value) ||
      mastercardExpression.test(cardNumber.value) ||
      amexExpression.test(cardNumber.value) ||
      discoverExpression.test(cardNumber.value) ||
      dinersClubExpression.test(cardNumber.value) ||
      maestroExpression.test(cardNumber.value) ||
      unionPayExpression.test(cardNumber.value)) {
      return null;
    }

    return {
      invalidCardNumber: true
    };
  }

  static matchPassword(controlName: string, matchingControlName: string): any {
    return (formGroup: any) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
  static sendWhithoutBono(bono): any {
    if (bono.pristine) {
      return null;
    }
    if (bono.value == 'ENVIAR SIN BONO') {
      return null;
    }

    return {
      invalidBono: true
    };
  }

  static carnet(digits): any {
    if (digits.value === '') {
      return null;
    }
    if (digits.pristine) {
      return null;
    }

    const DIGITS_REGEXP = /^[0-9]{11}$/;
    if (DIGITS_REGEXP.test(digits.value)) {
      return null;
    }

    return {
      invalidCarnet: true
    };
  }

  static cashback(cashback): any {

    if (cashback.pristine) {
      return null;
    }

    if (cashback.value === '') {
      return {
        invalidCashbackRequired: true
      };
    }

    if (!isNaN(cashback.value)) {
      return null;
    }

    return {
      invalidCashback: true
    };
  }

  static onlyInt(digits): any {
    if (digits.value === '') {
      return null;
    }
    if (digits.pristine) {
      return null;
    }

    const DIGITS_REGEXP = /^[0-9]*$/;
    if (DIGITS_REGEXP.test(digits.value)) {
      return null;
    }

    return {
      invalidInt: true
    };
  }

  static identity(identity): any {
    if (identity.value === '') {
      return null;
    }
    if (identity.pristine) {
      return null;
    }

    //const DIGITS_REGEXP = /^[0-9]*$/;
    if (identity.value.length == 11) {
      return null;
    }

    return {
      invalidIdentity: true
    };
  }

  private static ValidIdentityLength = (identity: string) => identity.length === 11;
}
