<div *ngIf="phoneVerification">
  <div class="modal-header pt-4">
    <h2 class="modal-title text-center">Verificación requerida </h2>
  </div>


  <div class="modal-body pt-2 d-flex flex-column align-items-center">

    <p class="text-center">Recupere y asegure su cuenta fácilmente. Le enviaremos un SMS para verificar su número.</p>
    <div class="w-100">
      <ngx-intl-tel-input [cssClass]="'d-block w-100 form-control custom'" [preferredCountries]="['us', 'ca']"
        [enablePlaceholder]="true" [selectedCountryISO]="isoCode" [enableAutoCountrySelect]="true" name="phone" [formControl]="phoneControl">
      </ngx-intl-tel-input>
    </div>
    <div *ngIf="phoneControl.errors && !phoneControl.pristine">
      <div *ngIf="phoneControl.errors.required" class="text-danger error">
        El número es
        requerido
      </div>
      <div *ngIf="phoneControl.errors.validatePhoneNumber && !phoneControl.errors.required" class="text-danger error">
        El número no es
        válido
      </div>
    </div>
    <p class="text-muted text-center mt-2 mb-0">Este es el número que verán cuando llame</p>

  </div>
  <div class="modal-footer d-flex flex-column align-items-center">
    <button *ngIf="waitTime == 0" type="button" [disabled]="phoneControl.invalid || phoneControl.pristine"
      class="btn btn-primary banner-button modal-banner-button" (click)="sendPhone()">Enviar código</button>
    <p *ngIf="waitTime != 0">Tiempo restante: <span class="bold">{{ convertToDate()}}</span></p>
    <p class="text-muted text-center mt-3">Al ingresar su número, acepta recibir SMS para verificar su cuenta. Cargos por uso de datos y mensajería podrían aplicar.
    <p>

  </div>
  <div *ngIf="loadingChange$ | async" class="backdrop" style="border-radius: 8px">
    <div class="text-center">
      <picture>
        <source srcset="assets/loading.webp" type="image/webp">
        <source srcset="assets/loading.gif" type="image/gif">
        <img style="height: 70px; width:70px" src="assets/loading.gif" alt="Cargando...">
      </picture>
    </div>
  </div>
</div>
<app-modal-phone-verification-code *ngIf="verificationCode" [phoneNumber]="model.phoneNumber" [visiblePhone]="model.phoneNumber"
  [access_token]="access_token" (response)="verificationCodeResponse($event)" (goBack)="editNumber()"></app-modal-phone-verification-code>