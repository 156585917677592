import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment.dev';

export class Utils {
    public static truncateDecimal(number: number) {
        if (Number.isInteger(number)) {
            return number.toFixed(2);
        } else {
            const numberToStirng = number.toString().split('.');
            return `${numberToStirng[0]}.${numberToStirng[1].substr(0, 2)}${numberToStirng[1].length == 1 ? 0 : ''}`;
        }
    }

    public static timeOutError(words: string) {
        const more30 = words.split(' ').length > 30;
        const hasNumber = words.match(new RegExp(/^(.*)[0-9]+(.*)$/)) !== null;
        let timeOutTotal = (words.split(' ').length * 1000) + 10000;
        if (more30) { timeOutTotal += 10000; }
        if (hasNumber) { timeOutTotal += 10000; }
        return timeOutTotal;
    }
}
