import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date().getTime() - +  new Date(value).getTime()) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
        return 'Justo ahora';
      const intervals = {
        'día': 86400,
        'hora': 3600,
        'minuto': 60,
        'segundo': 1
      };
      let counter;
      if (Math.floor(seconds / 604800) > 0) {
        return formatDate(new Date(value), 'dd/MM/yy', 'en');
      }
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {
          if (counter === 1) {
            return 'Hace ' + counter + ' ' + i; // singular (1 day ago)
          } else {
            return 'Hace ' + counter + ' ' + i + 's'; // plural (2 days ago)
          }
        }
      }
    }
    return value;
  }

}
