import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { BonusService } from '@app/services/bonus.service';
import { DiscountModel } from '@app/models/discount.model';
import { CashBackModel } from '@app/models/cashback.model';
import { RewardModel } from '@app/models/reward.model';
import { ModalQuestionCashbackComponent } from '../modal-question-cashback/modal-question-cashback.component';
import { Observable } from 'rxjs';

interface HasMoreDetails {
  id: number;
  showed: boolean;
}

@Component({
  selector: 'app-modal-rewards',
  templateUrl: './modal-rewards.component.html',
  styleUrls: ['./modal-rewards.component.css'],
  providers: [BonusService]
})
export class ModalRewardsComponent implements OnInit {

  @Input()
  selected: number;
  loading: Observable<boolean>;

  response: number;

  rewardsSummary: RewardModel;
  discountHasMore: boolean[] = [];
  cashbackHasMore: boolean[] = [];

  selectedTab = 1;

  constructor(public activeModal: NgbActiveModal,
    private rewardsService: BonusService,
    private modalService: NgbModal) {

    this.rewardsSummary = {
      availableCashback: 0,
      rewardsEarned: 0,
      cashback: [],
      discounts: [],
      success: false
    };

    this.response = 0;
  }


  ngOnInit() {
    this.selectedTab = this.selected || 1;
    this.loading = this.rewardsService.loading$;
    this.rewardsService.getRewards()
      .then(result => {
        this.rewardsSummary = result;
        this.response = 1;
        this.rewardsSummary.discounts.forEach(discount => { this.discountHasMore.push(false); });
        this.rewardsSummary.cashback.forEach(cashback => { this.cashbackHasMore.push(false); });
      }).catch(() => {
        this.response = 2;
      });
  }

  setDetailsDiscount(id: number) {
    const discountShowed = this.discountHasMore[id];
    this.discountHasMore[id] = !discountShowed;
  }

  getCashbackInfo() {
    const modalRef = this.modalService.open(ModalQuestionCashbackComponent, { centered: true });
    modalRef.componentInstance.availableCashback = this.rewardsSummary.availableCashback;
  }

  onTabChange($event) {
    this.rewardsService.getRewards()
      .then(result => {
        this.rewardsSummary = result;
        this.response = 1;
        this.rewardsSummary.discounts.forEach(discount => { this.discountHasMore.push(false); });
        this.rewardsSummary.cashback.forEach(cashback => { this.cashbackHasMore.push(false); });
      }).catch(() => this.response = 2);
      this.selectedTab = $event.nextId;
  }

}
