<div class="modal-header pt-4">
  <h2 class="modal-title text-center">¿Desea reclamar este número? </h2>
</div>


<div class="modal-body pt-2 d-flex flex-column align-items-center">
  <picture class="w-50 pb-4">
    <source srcset="https://www.cubatel.com/images-ads/cdn/assets/verification/claim_phone_number.webp" type="image/webp">
    <source srcset="https://www.cubatel.com/images-ads/cdn/assets/verification/claim_phone_number.png" type="image/png">
    <img class="w-100" src="https://www.cubatel.com/images-ads/cdn/assets/verification/claim_phone_number.png" alt="Reclamar número de teléfono">
  </picture>
  <p class="text-center mb-0">El número <span class="primary">{{phoneNumber}}</span> está vinculado a otra cuenta de Cubatel. Al
    reclamar este número lo eliminará de la otra cuenta y transferirá el saldo que tuviese a su cuenta actual. Por
    motivos de seguridad, se eliminarán todos los registros de llamadas existentes.</p>
</div>
<div class="modal-footer d-flex flex-column flex-sm-row align-items-center mb-4">
  <button type="button" class="btn btn-primary banner-button modal-banner-button d-flex d-sm-none mb-2"
    (click)="claimPhoneNumber()">Reclamar</button>
  <button type="button" class="btn btn-default banner-button modal-banner-button" (click)="cancel()">Ahora no</button>
  <button type="button" class="btn btn-primary banner-button modal-banner-button d-none d-sm-flex ml-sm-2"
    (click)="claimPhoneNumber()">Reclamar</button>
</div>
<div *ngIf="loadingChange$ | async" class="backdrop" style="border-radius: 8px">
  <div class="text-center">
    <picture>
      <source srcset="https://www.cubatel.com/images-ads/cdn/assets/loading.webp" type="image/webp">
      <source srcset="https://www.cubatel.com/images-ads/cdn/assets/loading.gif" type="image/gif">
      <img style="height: 70px; width:70px" src="https://www.cubatel.com/images-ads/cdn/assets/loading.gif" alt="Cargando...">
    </picture>
  </div>
</div>
