import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { SharedService } from './shared.service';
const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class GooglePlayReviewsService extends SharedService {

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  listRewards(from: number = 0, size: number = 9): Promise<any> {
    const url = 'api/Review/ExternalReviews?from=' + from + '&pageSize=' + size;
    return new Promise((resolve, reject) => {
      this._httpClient.get(baseUrl + url)
        .subscribe((response: any) => {
          resolve(response);
        }, (reason: any) => {
          reject(reason);
        });
    });
  }
}
