<div class="modal-header pt-4">
  <h5 class="modal-title ">Mis Recompensas</h5>
</div>


<div class="modal-body pt-2">

  <div class="d-flex flex-row justify-content-around align-items-start mb-4">
    <p class="text-center">Total de Recompensas <br><span class="bold">${{rewardsSummary.rewardsEarned}} USD</span></p>
    <picture>
      <source srcset="assets/icons/rewards-icons/box.png" type="image/webp">
      <source srcset="assets/icons/rewards-icons/box.png" type="image/png">
      <img class="gif" src="assets/icons/rewards-icons/box.png" alt="Recompensas">
    </picture>
  </div>
  <ul ngbNav #nav="ngbNav" class="nav-tabs" [activeId]="selectedTab" (navChange)="onTabChange($event)">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Descuentos</a>
      <ng-template ngbNavContent>
        <table *ngIf="rewardsSummary.discounts.length > 0" class="table table-condensed table-responsive discount">
          <tbody>
            <tr *ngFor="let item of rewardsSummary.discounts; let index">
              <td *ngIf="item.status != 0"><i
                  [ngClass]="{'stu-icon-recharche': item.serviceType == 0, 'stu-icon-nauta': item.serviceType == 1, 'stu-icon-calls': item.serviceType == 2, 'stu-icon-sms': item.serviceType == 3}"
                  class="stu primary"></i></td>
              <td *ngIf="item.status == 0"><i
                  [ngClass]="{'stu-icon-recharche': item.serviceType == 0, 'stu-icon-nauta': item.serviceType == 1, 'stu-icon-calls': item.serviceType == 2, 'stu-icon-sms': item.serviceType == 3}"
                  class="stu default"></i></td>
              <td scope="row">
                <div class="w-100">
                  <span *ngIf="!discountHasMore[item.id]"
                    [ngClass]="{'disabled-reward': item.status == 0}">{{item.description}}</span>
                  <span *ngIf="discountHasMore[item.id]">{{item.discountInfo}}</span>
                </div>
              </td>
              <td scope="amount">
                <div class="w-100 text-center d-flex flex-column">
                  <span *ngIf="item.status == 1" (click)="setDetailsDiscount(item.id)"><i
                      class="stu stu-icon-Info primary"></i></span>
                  <span *ngIf="item.status == 0" (click)="setDetailsDiscount(item.id)"><i
                      class="stu stu-icon-Info default"></i></span>
                  <span [ngClass]="{'bold': item.status != 0}">{{item.amountText}}</span>
                </div>

              </td>
            </tr>
          </tbody>
        </table>
        <div class="not-discount" *ngIf="rewardsSummary.discounts.length == 0 && (response == 2 || response == 1)">
          <h3 class="text-center">Sin descuentos...<br>¡pero solo por ahora!</h3>
          <p class="text-center">Revise esta pestaña periódicamente para<br>encontrar descuentos, paquetes
            promocionales<br>y ofertas
            rélampago de Cubatel</p>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>Reembolsos</a>
      <ng-template ngbNavContent>
        <div class="d-flex flex-row justify-content-center align-items-center my-4">
          <p class="text-center mx-3">Reembolso Disponible <br><span class="bold">${{rewardsSummary.availableCashback}}
              USD</span></p>
          <span (click)="getCashbackInfo()"><i class="stu stu-icon-Info primary mx-3"></i></span>
        </div>
        <table *ngIf="rewardsSummary.cashback.length > 0" class="table table-condensed table-responsive cashback">
          <thead>
            <tr>
              <th colspan="3">Últimas recompensas de reembolso</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of rewardsSummary.cashback">
              <td *ngIf="item.status != 0"><i class="stu stu-icon-cashback primary"></i>
              </td>
              <td *ngIf="item.status == 0"><i class="stu stu-icon-cashback default"></i>
              </td>
              <td scope="row">{{item.description}}<br>{{item.createdDate | date: 'dd/MM/yyyy'}}</td>
              <td><span *ngIf="!item.cashbackEarnedText"
                  [ngClass]="{'bold': item.status != 0}">{{item.amountText}}</span>
                <span *ngIf="item.cashbackEarnedText"
                  [ngClass]="{'bold': item.status != 0}">{{item.cashbackEarnedText}}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="not-discount" *ngIf="rewardsSummary.cashback.length == 0 && (response == 2 || response == 1)">
          <h3 class="text-center">Sin reembolso disponible</h3>
          <p class="text-center">¡Comience a enviar recargas y obtenga hasta un 5% de reembolso<br> por cada transacción
            Cubacel o Nauta completada!</p>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
  <!--<ngb-nav justify="center" #tabs [activeId]="selectedTab" (tabChange)="onTabChange($event)">
    <ngb-tab id="discounts">
      <ng-template ngbTabTitle>
        <div>Descuentos</div>
      </ng-template>
      <ng-template ngbTabContent>
        <table *ngIf="rewardsSummary.discounts.length > 0" class="table table-condensed table-responsive discount">
          <tbody>
            <tr *ngFor="let item of rewardsSummary.discounts; let index">
              <td *ngIf="item.status != 0"><i
                  [ngClass]="{'stu-icon-recharche': item.serviceType == 0, 'stu-icon-nauta': item.serviceType == 1, 'stu-icon-calls': item.serviceType == 2, 'stu-icon-sms': item.serviceType == 3}"
                  class="stu primary"></i></td>
              <td *ngIf="item.status == 0"><i
                  [ngClass]="{'stu-icon-recharche': item.serviceType == 0, 'stu-icon-nauta': item.serviceType == 1, 'stu-icon-calls': item.serviceType == 2, 'stu-icon-sms': item.serviceType == 3}"
                  class="stu default"></i></td>
              <td scope="row">
                <div class="w-100">
                  <span *ngIf="!discountHasMore[item.id]"
                    [ngClass]="{'disabled-reward': item.status == 0}">{{item.description}}</span>
                  <span *ngIf="discountHasMore[item.id]">{{item.discountInfo}}</span>
                </div>
              </td>
              <td scope="amount">
                <div class="w-100 text-center d-flex flex-column">
                  <span *ngIf="item.status == 1" (click)="setDetailsDiscount(item.id)"><i
                      class="stu stu-icon-Info primary"></i></span>
                  <span *ngIf="item.status == 0" (click)="setDetailsDiscount(item.id)"><i
                      class="stu stu-icon-Info default"></i></span>
                  <span [ngClass]="{'bold': item.status != 0}">{{item.amountText}}</span>
                </div>

              </td>
            </tr>
          </tbody>
        </table>
        <div class="not-discount" *ngIf="rewardsSummary.discounts.length == 0 && (response == 2 || response == 1)">
          <h3 class="text-center">Sin descuentos...<br>¡pero solo por ahora!</h3>
          <p class="text-center">Revise esta pestaña periódicamente para<br>encontrar descuentos, paquetes
            promocionales<br>y ofertas
            rélampago de Cubatel</p>
        </div>
      </ng-template>
    </ngb-tab>
    <ngb-tab id="cashback">
      <ng-template ngbTabTitle>
        <div>Reembolsos</div>
      </ng-template>
      <ng-template ngbTabContent>
        <div class="d-flex flex-row justify-content-center align-items-center my-4">
          <p class="text-center mx-3">Reembolso Disponible <br><span class="bold">${{rewardsSummary.availableCashback}}
              USD</span></p>
          <span (click)="getCashbackInfo()"><i class="stu stu-icon-Info primary mx-3"></i></span>
        </div>
        <table *ngIf="rewardsSummary.cashback.length > 0" class="table table-condensed table-responsive cashback">
          <thead>
            <tr>
              <th colspan="3">Últimas recompensas de reembolso</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of rewardsSummary.cashback">
              <td *ngIf="item.status != 0"><i class="stu stu-icon-cashback primary"></i>
              </td>
              <td *ngIf="item.status == 0"><i class="stu stu-icon-cashback default"></i>
              </td>
              <td scope="row">{{item.description}}<br>{{item.createdDate | date: 'dd/MM/yyyy'}}</td>
              <td><span *ngIf="!item.cashbackEarnedText"
                  [ngClass]="{'bold': item.status != 0}">{{item.amountText}}</span>
                <span *ngIf="item.cashbackEarnedText"
                  [ngClass]="{'bold': item.status != 0}">{{item.cashbackEarnedText}}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="not-discount" *ngIf="rewardsSummary.cashback.length == 0 && (response == 2 || response == 1)">
          <h3 class="text-center">Sin reembolso disponible</h3>
          <p class="text-center">¡Comience a enviar recargas y obtenga hasta un 5% de reembolso<br> por cada transacción
            Cubacel o Nauta completada!</p>
        </div>
      </ng-template>
    </ngb-tab>
    </ngb-tabset>-->
</div>
<div *ngIf="loading | async" class="backdrop" style="border-radius: 16px">
  <div class="text-center">
    <picture>
      <source srcset="assets/loading.webp" type="image/webp">
      <source srcset="assets/loading.gif" type="image/gif">
      <img style="height: 70px; width:70px" src="assets/loading.gif" alt="Cargando...">
    </picture>
  </div>
</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn btn-primary banner-button modal-banner-button" data-dismiss="modal"
    (click)="activeModal.close()" ngbAutofocus>CERRAR</button>
</div>
