import {createReducer, on} from '@ngrx/store';
import * as fromAuth from '../auth.state'
import * as authActions from './auth-actions'

export const initialState: fromAuth.State = {
  isAuthenticated: false,
  user: null,
  errorMessage: null
};
export const reducer = createReducer(
  initialState,
  on(authActions.LogInSuccess, (state,data) => {
    return {...state,user:data.payload,isAuthenticated:true,errorMessage:""}
  }),
  on(authActions.Logout, (state) => {
    return {...state,user:null,isAuthenticated:false,errorMessage:""}
  }),
);

