<!--MAIN-BANNER-->
<div id="mainBannerRemittance">
  <div
    class="mainBanner-container position-relative d-flex flex-column flex-md-row align-items-center justify-content-center">
    <div id="download-apps-top" class="d-flex flex-column pt-5 pt-md-0 pl-md-4">
      <div class="d-flex flex-column">
        <h1 class="font-weight-normal  Poppins text-center text-md-left"><span>LAS REMESAS A CUBA<br>NUNCA FUERON <div class="d-block d-md-none"></div> MÁS FÁCILES</span>
        </h1>
        <span class="text-center text-md-left pt-md-2 pt-lg-4 Poppins text-subtitle text-white font-weight-bold">
          Haz los envíos de dinero a Cuba<br>
          desde nuestra app.
        </span>
      </div>
      <div class="d-flex flex-column align-items-center align-items-md-start pt-4 mt-1">
        <img width="296" height="251"  class="d-flex d-sm-none d-xl-none position-relative" src="assets/remittance/Miguelin-movil.png" style="left: -20px"
             alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
        <button style="color: #4BBFAB; width: 280px;" id="btn-send-money" [disabled]="deepLink == 'remittance'"
          class="btn btn-white p-2 px-4 mb-4 mr-2 text-decoration-none text-center position-relative font-weight-bold"
          (click)="callToAction(DeepLinkType.Remittances, 'remesas', 'remittance')">
          <ng-container *ngIf="deepLink != 'remittance'; else snippet"></ng-container>
          Enviar dinero
        </button>
      </div>
    </div>
    <div id="banner-img" class="d-flex pl-5 pl-md-4 pt-md-5 justify-content-center align-items-end position-relative" style="z-index: 1">
      <img width="442" height="375" class="d-none d-md-flex" src="assets/remittance/Miguelin.png"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      <img width="442" height="375" class="d-none d-sm-flex d-md-none" src="assets/remittance/Miguelin.png"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
    </div>
    <picture class="position-absolute d-block d-md-none w-100" style="z-index: 0; bottom: 0">
      <source data-srcset="assets/remittance/background-mobile1.png" type="image/webp">
      <source data-srcset="assets/remittance/background-mobile1.png" type="image/png">
      <img class="lazyload w-100" alt="Envía dinero a Cuba"
           data-src="assets/remittance/background.png">
    </picture>
    <picture class="position-absolute d-none d-md-block" style="z-index: 0;">
      <source data-srcset="assets/remittance/background.png" type="image/webp">
      <source data-srcset="assets/remittance/background.png" type="image/png">
      <img style="float: right" class="lazyload w-100" alt="Envía dinero a Cuba"
           data-src="assets/remittance/background.png">
    </picture>

  </div>
</div>
<!--END-MAIN-BANNER-->

<!-- APPS SECTION # 1 -->
<!--<div id="remittance-office" class="d-flex justify-content-md-center align-items-center position-relative pt-4"
  style="background-color: #0c8efe;">
  <div class="d-flex justify-content-start align-items-center position-relative">
    <picture class="d-flex d-md-none">
      <source data-srcset="assets/remittance/section-mobile-remittance-office.png" type="image/webp">
      <source data-srcset="assets/remittance/section-mobile-remittance-office.png" type="image/png">
      <img width="320" height="465" class="lazyload" data-src="assets/remittance/section-mobile-remittance-office.png"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
    </picture>
    <picture class="d-none d-md-flex d-lg-none">
      <source data-srcset="assets/remittance/section-desktop-remittance-office.png" type="image/webp">
      <source data-srcset="assets/remittance/section-desktop-remittance-office.png" type="image/png">
      <img width="672" height="255" class="lazyload" data-src="assets/remittance/section-desktop-remittance-office.png"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
    </picture>
    <picture class="d-none d-lg-flex d-xl-none">
      <source data-srcset="assets/remittance/section-desktop-remittance-office.png" type="image/webp">
      <source data-srcset="assets/remittance/section-desktop-remittance-office.png" type="image/png">
      <img width="992" height="376" class="lazyload" data-src="assets/remittance/section-desktop-remittance-office.png"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
    </picture>
    <picture class="d-none d-xl-flex">
      <source data-srcset="assets/remittance/section-desktop-remittance-office.png" type="image/webp">
      <source data-srcset="assets/remittance/section-desktop-remittance-office.png" type="image/png">
      <img width="1100" height="417" class="lazyload" data-src="assets/remittance/section-desktop-remittance-office.png"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
    </picture>

    <div id="send-remittance-3" class="d-none d-md-flex flex-column position-absolute h-100 px-4">
      <img class="position-relative d-none d-md-flex d-lg-none" width="170" height="71"
        src="assets/remittance/send-remittance-4.svg">
      <img class="position-relative d-none d-lg-flex" style="top: 5px" width="214" height="88"
        src="assets/remittance/send-remittance-4.svg">
      <div class="position-relative my-auto" style="text-transform: none; top: -10px">
        <h2 class="h1" style="text-transform: none">Envía dinero a
          Cuba </h2>-->
        <!--<span class="h1" style="text-transform: none">Encuéntranos en 734 W 49Th St,
          Hialeah.</span>-->
      <!--</div>

      <img class="align-self-end d-none d-md-flex d-lg-none" width="200" height="73"
        src="assets/remittance/send-remittance-3.svg">
      <img class="align-self-end d-none d-lg-flex" width="279" height="102"
        src="assets/remittance/send-remittance-3.svg">
      <div class="d-flex justify-content-center justify-content-md-start pb-4 pb-lg-5 px-2 position-absolute"
        style="bottom:0">
        <a class="btn btn-transparent py-2 px-4 text-decoration-none text-center text-white"
          href="https://www.google.com/maps?cid=1153925969485470496">
          SABER MÁS
        </a>
      </div>
    </div>
    <div id="send-remittance-4" class="d-flex d-md-none flex-column position-absolute h-100 pl-4">
      <span class="h1 text-left position-relative pl-4 pt-3" style="top: -10px">
        <h2 class="h1" style="text-transform: none;font-size: 24px;">Envía dinero a Cuba
          </h2>-->
        <!--<span class="font-weight-normal" style="text-transform: none;font-size: 24px;">Encuéntranos en</span><br>
        <span style="text-transform: none;" style="text-transform: none;font-size: 21px;">734 W 49Th St, Hialeah.</span>-->
      <!--</span>
      <div class="d-flex justify-content-end pt-5">
        <a class="btn btn-transparent py-2 px-4 text-decoration-none text-center text-white"
          href="https://www.google.com/maps?cid=1153925969485470496">
          SABER MÁS
        </a>
      </div>
    </div>

  </div>
</div>-->
<!-- END APPS SECTION # 1 -->

<!-- PRODUCTS SECTION -->
<div id="products" class="d-flex flex-column align-items-center">
  <div class="d-md-flex justify-content-center d-none">
    <div class="d-flex flex-column align-items-center px-4">
      <img width="111" height="111" alt="Remesas" src="https://www.cubatel.com/images-ads/cdn/assets/remittance/low_commissions.svg">
      <span class="h2 font-weight-bold">Remesas a Cuba</span>
      <p class="font-weight-bold text-center">Transfiere a tarjetas bancarias habilitadas en<br /> Cuba: BANDEC | BPA
        |<br> Banco Metropolitano.</p>
    </div>
    <div class="d-flex flex-column align-items-center px-4">
      <img width="111" height="111" alt="Seguras" src="https://www.cubatel.com/images-ads/cdn/assets/remittance/secure_payment.svg">
      <span class="h2 font-weight-bold">Seguras</span>
      <p class="font-weight-bold text-center">Desde la aplicación <!--o personalmente<br> en nuestra oficina, ubicada en<br>
        734 W 49Th St, Hialeah FL 33012.--></p>
    </div>
    <div class="d-flex flex-column align-items-center px-4">
      <img width="111" height="111" alt="Rápidas" src="https://www.cubatel.com/images-ads/cdn/assets/remittance/fast_shipping.svg">
      <span class="h2 font-weight-bold">Rápidas</span>
      <p class="font-weight-bold text-center">El envío de dinero a Cuba<br> llega a tu familia en 1 o 2 días hábiles.
      </p>
    </div>
  </div>
  <div id="sliders-promotions" class="carousel slide d-block d-md-none mt-auto pb-5 w-75 h-50" data-touch="true"
    data-pause="false" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#sliders-promotions" data-slide-to="0" class="active"></li>
      <li data-target="#sliders-promotions" data-slide-to="1"></li>
      <li data-target="#sliders-promotions" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item mb-2 active" data-interval="20000" data-index="0">
        <div class="d-flex flex-column align-items-center">
          <img width="111" height="111" alt="Remesas"
            src="https://www.cubatel.com/images-ads/cdn/assets/remittance/low_commissions.svg">
          <span class="h2 font-weight-bold">Remesas</span>
          <p class="font-weight-bold text-center">A tarjetas bancarias habilitadas en<br> Cuba: BANDEC | BPA |
            Banco<br>Metropolitano.</p>
        </div>
      </div>
      <div class="carousel-item mb-2" data-interval="20000" data-index="1">
        <div class="d-flex flex-column align-items-center">
          <img width="111" height="111" alt="Seguras"
            src="https://www.cubatel.com/images-ads/cdn/assets/remittance/secure_payment.svg">
          <span class="h2 font-weight-bold">Seguras</span>
          <p class="font-weight-bold text-center">Desde la aplicación <!--o personalmente<br> en nuestra oficina, ubicada
            en<br>
            734 W 49Th St, Hialeah FL 33012.--></p>
        </div>
      </div>
      <div class="carousel-item mb-2" data-interval="20000" data-index="2">
        <div class="d-flex flex-column align-items-center">
          <img width="111" height="111" alt="Rápidas" src="https://www.cubatel.com/images-ads/cdn/assets/remittance/fast_shipping.svg">
          <span class="h2 font-weight-bold">Rápidas</span>
          <p class="font-weight-bold text-center">El envío de dinero a Cuba<br> llega a tu familia en 1 o 2 días
            hábiles.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END PRODUCTS SECTION -->


<!-- APPS SECTION # 1 -->
<div id="appsSectionMarket" class="d-flex flex-column flex-lg-row">
  <div class="pl-md-4">
    <h2 class="Poppins d-none d-lg-flex flex-column download-text-1">DESCARGA LA APP <br><span
        style="text-transform: none;">y envía dinero a Cuba.</span>
    </h2>
    <!--<span class="Poppins d-none d-lg-flex justify-content-start download-text-2">y envía dinero a Cuba.</span>-->
    <h2 class="flex-column align-items-center text-center flex d-lg-none Poppins">DESCARGA LA APP <br><span
        style="text-transform: none;">y envía dinero a Cuba.</span>
    </h2>
    <!--<span class="justify-content-center d-flex d-lg-none Poppins">y envía dinero a Cuba.</span>-->
    <div id="as1-links-apps" class="mt-4 mb-5 d-flex justify-content-start flex-column flex-lg-row">
      <button [disabled]="deepLink == 'appIos'"
        class="btn btn-secondary bg-transparent text-decoration-none mr-lg-3 d-flex justify-content-center position-relative"
        (click)="callToAction(DeepLinkType.AppIos, 'remesas', 'appIos')" aria-label="App Store">
        <picture class="d-flex d-lg-none">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/app-store-2.webp" type="image/webp" />
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/app-store-2.png" type="image/png" />
          <img class="lazyload" width="140" height="40"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/app-store-2.png" alt="App Store" />
        </picture>
        <picture class="d-none d-lg-flex">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/app-store-2.webp" type="image/webp" />
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/app-store-2.png" type="image/png" />
          <img class="lazyload" width="160" height="45"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/app-store-2.png" alt="App Store" />
        </picture>
        <ng-container *ngIf="deepLink != 'appIos'; else snippet"></ng-container>
      </button>
      <button [disabled]="deepLink == 'appAndroid'"
        class="btn btn-secondary bg-transparent text-decoration-none mt-3 mt-lg-0 ml-lg-3 d-flex justify-content-center position-relative"
        (click)="callToAction(DeepLinkType.AppAndroid, 'remesas', 'appAndroid')" aria-label="Google Play">
        <picture class="d-flex d-lg-none">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/google-play-2.webp" type="image/webp" />
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/google-play-2.png" type="image/png" />
          <img class="lazyload" width="140" height="40"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/google-play-2.png" alt="Google Play" />
        </picture>
        <picture class="d-none d-lg-flex">
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/google-play-2.webp" type="image/webp" />
          <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets-v3/home/google-play-2.png" type="image/png" />
          <img class="lazyload" width="160" height="45"
            data-src="https://www.cubatel.com/images-ads/cdn/assets-v3/home/google-play-2.png" alt="Google Play" />
        </picture>
        <ng-container *ngIf="deepLink != 'appAndroid'; else snippet"></ng-container>
      </button>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center position-relative">
    <picture class="d-none d-lg-flex">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets/remittance/heidy.webp" type="image/webp">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets/remittance/heidy.png" type="image/png">
      <img width="381" height="448" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets/remittance/heidy.png"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
    </picture>
    <picture class="d-flex d-lg-none">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets/remittance/heidy.webp" type="image/webp">
      <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets/remittance/heidy.png" type="image/png">
      <img width="240" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets/remittance/heidy.png"
        alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
    </picture>

    <div id="send-remittance-2" class="position-absolute h-100 w-100 pt-3">
      <picture class="d-none d-xl-block">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets/remittance/send-remittance-2.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets/remittance/send-remittance-2.png" type="image/png">
        <img width="240" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets/remittance/send-remittance-2.png"
          alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      </picture>
      <picture class="d-none d-md-block d-xl-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets/remittance/send-remittance-2.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets/remittance/send-remittance-2.png" type="image/png">
        <img width="180" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets/remittance/send-remittance-2.png"
          alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      </picture>
      <picture class="d-block d-md-none">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets/remittance/send-remittance-2.webp" type="image/webp">
        <source data-srcset="https://www.cubatel.com/images-ads/cdn/assets/remittance/send-remittance-2.png" type="image/png">
        <img width="120" class="lazyload" data-src="https://www.cubatel.com/images-ads/cdn/assets/remittance/send-remittance-2.png"
          alt="Cubatel - Llamadas, SMS, Recargas Nauta y Recargas a Cuba">
      </picture>
    </div>

  </div>
</div>
<!-- END APPS SECTION # 1 -->

<!-- STEPS SECTION -->
<div id="products" class="d-flex flex-column">
  <h2 class="stu-primary-color w-100 text-center mt-4 mb-md-4 Poppins">¿Cómo enviar dinero a Cuba?</h2>
  <div class="d-flex flex-column justify-content-center py-4 m-auto">
    <div class="steps-container d-flex flex-column flex-md-row justify-content-center align-items-center py-4 ">
      <div clas="d-flex flex-column ">
        <div class="steps-container-img m-auto w-75"><img class="w-100"
            src="https://www.cubatel.com/images-ads/cdn/assets/remittance/step-1-2.png"></div>
        <div class="steps-container-text d-flex flex-column pl-md-5 ml-md-5 align-items-center w-75  m-auto">
          <p class="steps-text text-center mt-2">Verificación de la identidad<br> del remitente.</p>
        </div>
      </div>
      <div clas="d-flex flex-column ">
        <div class="steps-container-img m-auto w-75"><img class="w-100"
            src="https://www.cubatel.com/images-ads/cdn/assets/remittance/step-2-3.png"></div>
        <div class="steps-container-text d-flex flex-column pl-md-5 ml-md-5 align-items-center w-75 m-auto">
          <p class="steps-text text-center mt-2">Ingresa el monto del<br> envío de dinero a Cuba.</p>
        </div>
      </div>
    </div>
    <div class="steps-container d-flex flex-column flex-md-row justify-content-center align-items-center py-4 ">
      <div clas="d-flex flex-column ">
        <div class="steps-container-img m-auto w-75"><img class="w-100"
            src="https://www.cubatel.com/images-ads/cdn/assets/remittance/step-3.png"></div>
        <div class="steps-container-text d-flex flex-column pl-md-5 ml-md-5 align-items-center w-75  m-auto">
          <p class="steps-text text-center mt-2">Selecciona un beneficiario o<br> añade uno nuevo.</p>
        </div>
      </div>
      <div clas="d-flex flex-column ">
        <div class="steps-container-img m-auto w-75"><img class="w-100"
            src="https://www.cubatel.com/images-ads/cdn/assets/remittance/step-4.png"></div>
        <div class="steps-container-text d-flex flex-column pl-md-5 ml-md-5 align-items-center w-75  m-auto">
          <p class="steps-text text-center mt-2">Selecciona un método de<br> pago para tu remesa a Cuba</p>
        </div>
      </div>
    </div>
    <div class="steps-container d-flex flex-column flex-md-row justify-content-center align-items-center py-4 ">
      <div clas="d-flex flex-column ">
        <div class="steps-container-img m-auto w-75"><img class="w-100"
            src="https://www.cubatel.com/images-ads/cdn/assets/remittance/step-5.png"></div>
        <div class="steps-container-text d-flex flex-column pl-md-5 ml-md-5 align-items-center w-75  m-auto">
          <p class="steps-text text-center mt-2">¡Listo! Realizaste tu<br> envío de dinero
            a Cuba.</p>
        </div>
      </div>
      <div clas="d-flex flex-column ">
        <div class="steps-container-img m-auto w-75"><img class="w-100"
            src="https://www.cubatel.com/images-ads/cdn/assets/remittance/step-6.png"></div>
        <div class="steps-container-text d-flex flex-column pl-md-5 ml-md-5 align-items-center w-75  m-auto">
          <p class="steps-text text-center mt-2">Recibirás un comprobante de<br> tu remesa a Cuba.</p>
        </div>
      </div>
    </div>

  </div>
</div>
<!-- END STEPS SECTION -->

<!-- CONTACT AS SECTION-->
<div id="contact-as" class="d-flex flex-column align-items-center py-5">
  <div class="d-md-flex justify-content-center d-none m-auto">
    <a class="d-flex flex-column align-items-center p-4" href="tel://18332822835" aria-label="Llámanos">
      <img class="py-2 pt-3" width="60" alt="Llámanos" src="https://www.cubatel.com/images-ads/cdn/images/Emails/Remittance/call.svg">
      <span class="h3 font-weight-bold pt-2">Llámanos</span>
      <p class="font-weight-normal text-center h5">+ 1 833-282-2835</p>
    </a>
    <a class="d-flex flex-column align-items-center p-4" href="mailto://soporte@cubatel.com" aria-label="Escríbenos">
      <img class="py-2" width="55" alt="Escríbenos" src="https://www.cubatel.com/images-ads/cdn/images/Emails/Remittance/email.svg">
      <span class="h3 font-weight-bold pt-2">Escríbenos</span>
      <p class="font-weight-normal text-center h5">soporte@cubatel.com</p>
    </a>
    <span class="d-flex flex-column align-items-center p-4 cursor-pointer chat" (click)="startChat()"
      aria-label="Chatea">
      <img class="py-2" width="47" alt="Chatea" src="https://www.cubatel.com/images-ads/cdn/images/Emails/Remittance/chat.svg">
      <span class="h3 font-weight-bold pt-2">Chatea</span>
      <p class="font-weight-normal text-center h5">ahora con nosotros</p>
    </span>
    <!--<a class="d-flex flex-column align-items-center p-4" href="https://www.google.com/maps?cid=1153925969485470496"
      aria-label="Visítanos">
      <img class="py-2" width="33" alt="Visítanos" src="https://www.cubatel.com/images-ads/cdn/images/Emails/Remittance/marker.svg">
      <span class="h3 font-weight-bold pt-2">Visítanos</span>
      <p class="font-weight-normal text-center h5">734 W 49th St, Hialeah,
        FL 33012</p>
    </a>-->
  </div>
  <div id="sliders-contact-as" class="carousel slide d-block d-md-none mt-auto pb-5 w-75 h-50" data-touch="true"
    data-pause="false" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#sliders-contact-as" data-slide-to="0" class="active"></li>
      <li data-target="#sliders-contact-as" data-slide-to="1"></li>
      <li data-target="#sliders-contact-as" data-slide-to="2"></li>
      <li data-target="#sliders-contact-as" data-slide-to="3"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item mb-2 active" data-interval="20000" data-index="0">
        <a class="d-flex flex-column align-items-center p-4" href="tel://18332822835" aria-label="Llámanos">
          <img class="py-2 pt-3" width="60" alt="Llámanos"
            src="https://www.cubatel.com/images-ads/cdn/images/Emails/Remittance/call.svg">
          <span class="h3 font-weight-bold pt-2">Llámanos</span>
          <p class="font-weight-normal text-center h5">+ 1 833-282-2835</p>
        </a>
      </div>
      <div class="carousel-item mb-2" data-interval="20000" data-index="1">
        <a class="d-flex flex-column align-items-center p-4" href="mailto://soporte@cubatel.com"
          aria-label="Escríbenos">
          <img class="py-2" width="55" alt="Escríbenos"
            src="https://www.cubatel.com/images-ads/cdn/images/Emails/Remittance/email.svg">
          <span class="h3 font-weight-bold pt-2">Escríbenos</span>
          <p class="font-weight-normal text-center h5">soporte@cubatel.com</p>
        </a>
      </div>
      <div class="carousel-item mb-2" data-interval="20000" data-index="2">
        <a class="d-flex flex-column align-items-center p-4" href="https://www.cubatel.com" aria-label="Chatea">
          <img class="py-2" width="47" alt="Chatea" src="https://www.cubatel.com/images-ads/cdn/images/Emails/Remittance/chat.svg">
          <span class="h3 font-weight-bold pt-2">Chatea</span>
          <p class="font-weight-normal text-center h5">ahora con nosotros</p>
        </a>
      </div>
      <!--<div class="carousel-item mb-2" data-interval="20000" data-index="2">
        <a class="d-flex flex-column align-items-center p-4" href="https://www.google.com/maps?cid=1153925969485470496"
          aria-label="Visítanos">
          <img class="py-2" width="33" alt="Visítanos"
            src="https://www.cubatel.com/images-ads/cdn/images/Emails/Remittance/marker.svg">
          <span class="h3 font-weight-bold pt-2">Visítanos</span>
          <p class="font-weight-normal text-center h5">734 W 49th St, Hialeah,
            FL 33012</p>
        </a>
      </div>-->
    </div>
  </div>
</div>
<!-- END CONTACT AS SECTION-->
<ng-template #snippet>
  <div class="backdrop" style="border-radius: 25px">
    <div class="snippet" data-title="dot-pulse">
      <div class="stage">
        <div class="dot-pulse"></div>
      </div>
    </div>
  </div>
</ng-template>
