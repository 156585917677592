import { createAction, props } from "@ngrx/store";
import { MarketProduct } from "../../_models/product.interface";
import { ICartItemResponse, ICartRequest, ICartUpdateResponse } from "../../shopping-cart/_models/cart.interface";
import { ShippingTypeEnum } from "@app/marketplace/core/enum/shippingType";
import { ViewTypeEnum } from "@app/marketplace/core/enum/viewTypeEnum";

export const FETCH_CART = '[CART] Fetch cart items';
export const FETCH_CART_SUCCESS = '[CART] Fetch cart items success';
export const FETCH_CART_FAILURE = '[CART] Fetch cart items failure';

export const UPDATE_CART = '[CART] Update cart item';
export const UPDATE_CART_SUCCESS = '[CART] Update cart item success';
export const UPDATE_CART_FAILURE = '[CART] Update cart item failure';
export const CANCEL_CART_REQUEST = '[CART] Cancel cart request';

export const PUSH_TO_CART= '[CART] Push to cart';
export const PUSH_TO_CART_SUCCESS= '[CART] Push to cart success';

export const PULL_FROM_CART= '[CART] Pull from cart';
export const PULL_FROM_CART_SUCCESS= '[CART] Pull from cart success';

export const DELETE_FROM_CART= '[CART] Delete from cart';
export const DELETE_FROM_CART_SUCCESS= '[CART] Delete from cart success';

export const CLEAR_CART= '[CART] Clear cart';
export const CLOSE_ERROR_MESSAGE = '[CART] Close error message';

export const SET_SHIPPINGTYPE= '[CART] Set Shipping Type';
export const SET_SHIPPINGTYPE_SUCCESS= '[CART] Set Shipping Type success';
export const SET_SHIPPINGTYPE_FAILURE = '[CART] Set Shipping Type failure';

export const FetchCart = createAction(FETCH_CART);
export const FetchCartSuccess = createAction(
  FETCH_CART_SUCCESS,
  props<{data: ICartItemResponse}>()
);
export const FetchCartFailure= createAction(FETCH_CART_FAILURE);
export const CancelCartRequest = createAction(CANCEL_CART_REQUEST);

export const UpdateCart = createAction(
  UPDATE_CART,
  props<{code: string,quantity:number}>()
);
export const UpdateCartSuccess = createAction(
  UPDATE_CART_SUCCESS,
  props<{data: ICartUpdateResponse}>()
);
export const UpdateCartFailure = createAction(UPDATE_CART_FAILURE);

export const PushToCart = createAction(
  PUSH_TO_CART,
  props<{
    product: MarketProduct,
    viewType:ViewTypeEnum
  }>()
);
export const PushToCartSuccess = createAction(
  PUSH_TO_CART_SUCCESS,
  props<{
    product:MarketProduct,
    viewType:ViewTypeEnum,
    quantity:number}>()
);

export const PullFromCart = createAction(
  PULL_FROM_CART,
  props<{product: MarketProduct,viewType:ViewTypeEnum}>()
);
export const PullFromCartSuccess = createAction(
  PULL_FROM_CART_SUCCESS,
  props<{product: MarketProduct}>()
);

export const DeleteFromCart = createAction(
  DELETE_FROM_CART,
  props<{product: any,viewType:ViewTypeEnum}>()
);
export const DeleteFromCartSuccess = createAction(
  DELETE_FROM_CART_SUCCESS,
  props<{product: any}>()
);
export const SetShippingType= createAction(
  SET_SHIPPINGTYPE,
  props<{product: MarketProduct,shipping:ShippingTypeEnum,viewType:ViewTypeEnum}>()
);
export const SetShippingSuccess = createAction(SET_SHIPPINGTYPE_SUCCESS);
export const SetShippingFailure = createAction(SET_SHIPPINGTYPE_FAILURE);

export const ClearCart = createAction(CLEAR_CART);
export const CloseErrorMessage = createAction(CLOSE_ERROR_MESSAGE);

