import { Account } from '@app/models/account';
import { PriceModel, PriceModelDetails } from '@app/models/price.model';
import { StuTransactionAccountModel } from './checkout/stu-transaction-model';

/**
 * Created by Elaine Lopez on 5/1/2019.
 */

export class RechargeModel {

  accountsToRecharge: StuTransactionAccountModel[] = [];
  sendRechargeNow: boolean;
  sendSms: boolean;
  message: string;
  deliveryNumber?: string;
  price: PriceModelDetails;
  cashback: number;
  eligibleCashback: number;
  availableCashback: number;
  discount: number;
  eligibleExtraDiscount: number;
  totalDiscount: number;
  isExclusive: boolean;
  landingPage = false;
  izcr: boolean;
  serviceType: any; // Cubacel=0, Nauta=1,Sms=2
  isPrerechargeMode?: boolean;
  utmSource?: any;
  maxCashback: number;
  transactionId?: number;
  fromHome: boolean;
  rechargeAmount: number;
  code?: string;
  promoType?: number;
  offerType?: number;
  promotionAmountText: string;

  constructor(recharge?) {
    recharge = recharge || {};
  }
}

