export class WindowScrolling {

    constructor() {

    }

    // ---
    // PUBLIC METHODS.
    // ---

    // I disable the scrolling feature on the main viewport.
    static disable(): void {
        const body = document.getElementById('body-content');
        body.setAttribute('class', 'home content');

    }

    // I re-enable the scrolling feature on the main viewport.
    static enable(): void {
        const body = document.getElementById('body-content');
        body.setAttribute('class', 'home');

    }

}
