import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {RechargeModel} from '@app/models/recharge.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  loadingFromLPSubject = new BehaviorSubject<boolean>(false);
  loadingFromLP = this.loadingFromLPSubject.asObservable();

  private toRechargeSource = new BehaviorSubject<RechargeModel>(new RechargeModel());
  toRecharge = this.toRechargeSource.asObservable();

  private toGetSmsSource = new BehaviorSubject<any>({izcr: false, activeGuard: false});
  toGetSms = this.toGetSmsSource.asObservable();

  selectedPriceSubject = new BehaviorSubject<any>({});
  isExclusiveSubject = new BehaviorSubject<boolean>(false);

  constructor() { }

  changeMessage(toRechargeMessage: RechargeModel) {
    this.toRechargeSource.next(toRechargeMessage);
  }

  changeGetSms(smsModel: any) {
    this.toGetSmsSource.next(smsModel);
  }
}
