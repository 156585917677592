import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadAppComponent } from './download-app.component';

@NgModule({
  declarations: [DownloadAppComponent],
  imports: [
    CommonModule,
  ],
  exports: [
      DownloadAppComponent
  ]
})
export class DownLoadAppSeoModule { }
