export class LazyLoad {
    static loadStyle(styleName: string, document: Document) {
        const themeLink = document.getElementById(styleName) as HTMLLinkElement;
        if (!themeLink) {
            const head = document.getElementsByTagName('head')[0];
            const style = document.createElement('link');
            style.id = styleName;
            style.rel = 'preload';
            style.href = `${styleName}.css`;
            style.as = 'style';
            head.appendChild(style);
            style.rel = 'stylesheet';
        }
    }

    static loadScripts(scriptUrl: string, document: Document, placeId = 'top') {
        const themeScript = document.getElementById(scriptUrl) as HTMLScriptElement;
        if (!themeScript) {
            return Promise.resolve(resolve => {
                const place = placeId == 'top' ? document.getElementsByTagName('head')[0] : document.getElementsByTagName('body')[0];
                const scriptElement = document.createElement('script');
                scriptElement.src = `${scriptUrl}.js`;
                scriptElement.id = scriptUrl;
                scriptElement.async = true;
                scriptElement.onload = resolve;
                place.appendChild(scriptElement);
            });
        }
    }
}

