import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromStorage from '@app/marketplace/storage/storage.state';

export const cartFeature = createFeatureSelector<fromStorage.AppStorageState>(fromStorage.LAYOUT_FEATURE_KEY);
export const selectCart = createSelector(
  cartFeature,
  (state: fromStorage.AppStorageState) => {
    if (state) return state.basket
    else return {
      data:{
        minCount: 0,
        maxCount: 50,
        maxPrice: 5000,
        minPrice: 0,
        totalItems: 0,
        totalPrice: 0,
        totalPriceToShow: "",
        signature: null,
        items:[]
      },
      isLoading:false
    };
  }
);

export const selectData = createSelector(
  cartFeature,
  (state: fromStorage.AppStorageState) => {
    if (state.basket?.data?.items && state.basket?.data?.items.length > 0) {
      state.basket.data.items = state.basket?.data?.items.map(x => {
        if (x.shortText?.length > 25) {
          x.summaryText = x.shortText.split(' ').slice(0, 24).join(' ') + '...';
          x.showDescriptionSummary = true;
        } else {
          x.summaryText = '';
          x.showDescriptionSummary = false;
        }
        return x;
      });
    }
    return state.basket.data;
  }
);

export const selectIsLoading = createSelector(
  cartFeature,
  (state: fromStorage.AppStorageState) => {
    return state.basket.isLoading;
  }
);

export const selectItem = (code: string) => createSelector(
  cartFeature,
  (state: fromStorage.AppStorageState) => {
    return state.basket.data.items.find(x => x.code == code)??{};
  }
);

export const selectShippings= (product: any) => createSelector(
  cartFeature,
  (state: fromStorage.AppStorageState) => {
    var attributes=product.attributes? Object.entries(product.attributes):[];
    var id=attributes.length>0?attributes.map(([k, v]) => `${product.code}${k}${v}`).join(''):product.code;
    return state.basket.data.items.find(x => x.id == id)?.allowedShippingTypes??[];
  }
);

/* export const selectShippingType = (code: string) => createSelector(
  cartFeature,
  (state: fromStorage.AppStorageState) => {
    return state.basket.data.items.find(x => x.code == code)?.shippingType??0;
  }
);
 */
export const selectShippingType = (product: any) => createSelector(
  cartFeature,
  (state: fromStorage.AppStorageState) => {
    var attributes=product.attributes? Object.entries(product.attributes):[];
    var id=attributes.length>0?attributes.map(([k, v]) => `${product.code}${k}${v}`).join(''):product.code;
    return state.basket.data.items.find(x => x.id == id)?.shippingType??0;
  }
);

export const selectMessage = createSelector(
  cartFeature,
  (state: fromStorage.AppStorageState) => {
    return state.basket.message;
  }
);

export const selectSuccess = createSelector(
  cartFeature,
  (state: fromStorage.AppStorageState) => {
    return state.basket.success;
  }
);

export const selectNotification = createSelector(
  cartFeature,
  (state: fromStorage.AppStorageState) => {
    return state.basket.notification;
  }
);

export const selectShipmentsItemNotification = () => createSelector(
  cartFeature,
  (state: fromStorage.AppStorageState) => {
    return state.basket?.notification??{
      messageType: 0,
      message: "",
      color: ""
   };
  }
);
