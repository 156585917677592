export class DeepLinkModel {
  id: DeepLinkType;
  src: string;
  ed: any;
  sid: string;
  dti: string;

}

export enum DeepLinkType {
  None = -1,
  HomePage = 0,
  Remittances = 1,
  AppAndroid = 2,
  AppIos = 3,
  Market = 4,
  Calls = 5,
  Recharges = 6,
  MarketSupplier = 7
}
