import { Store } from "@ngrx/store";
import * as cartActions from "../../products/state/shopping-cart/cart-action";
import { SeoContent } from "@app/marketplace/seo-content/seo-content";
import { Meta, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ViewTypeEnum } from "@app/marketplace/core/enum/viewTypeEnum";
export class PushToCartComponent extends SeoContent {
  constructor(route: ActivatedRoute,
              metaService: Meta,
              titleService: Title,
              router: Router) {
   super(route,metaService,titleService,router);
  }
  protected flyToElement(flyer:any,product:any,viewType:ViewTypeEnum,store: Store<any>) {
    var flyingTo=$(window).width() > 767?$('.cart'):$('.cartResponsive');
    var divider = 3;
    var flyerClone = $(flyer).clone().attr('class', 'cloneImg');
    $(flyerClone).css(
      {
        position: 'absolute',
        top: $(flyer).offset().top + "px",
        left: $(flyer).offset().left + "px",
        width: flyer.width()+"px",
        height: flyer.height()+"px",
        opacity: 1, 'z-index': 10000
      });
    $('body').append($(flyerClone));
    var gotoX = $(flyingTo).offset().left + ($(flyingTo).width() / 2) - ($(flyer).width() / divider) / 2;
    var gotoY = $(flyingTo).offset().top + ($(flyingTo).height() / 2) - ($(flyer).height() / divider) / 2;
    $(flyerClone).animate({
      opacity: 0.4,
      left: gotoX,
      top: gotoY,
      width: $(flyer).width() / divider,
      height: $(flyer).height() / divider
    }, 700,
      function () {
        $(flyingTo).fadeOut('fast', function () {
          $(flyingTo).fadeIn('fast', function () {
            $(flyerClone).fadeOut('fast', function () {
              //$(flyerClone).remove();
              $(".cloneImg").remove();
              store.dispatch(cartActions.PushToCart({product,viewType}));
            });
          });
        });
      });
  }
}

