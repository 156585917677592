/**
 * Created by Elaine Lopez on 4/19/2019.
 */


import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, timeout, switchMap, filter, take } from 'rxjs/operators';

import { LoginService } from '@app/services/login.service';
import { StorageService } from '@app/services/storage.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@env/environment';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalExpiredSessionComponent } from '@app/shared/components/modal-expired-session/modal-expired-session.component';
import { GooglePlayReviewsService } from '@app/services/google-play-reviews.service';
import { Utils } from './utils';
const baseUrl = environment.baseUrl;
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private refreshGoogleTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private refreshGoogleTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private storageService: StorageService,
    private loginService: LoginService,
    private googlePlayService: GooglePlayReviewsService,
    private router: Router,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(/*timeout(30000),*/ catchError(err => {
      switch (err.status) {
        case 401: {
          const user: any = JSON.parse(localStorage.getItem('currentUser'));
          if (user) {
            return this.refrechTokenUnauthorize(request, next);
          }
          return throwError(err);
        }
        case 415: { //415 Unsupported Media Type client error (Cancel Request)
          //console.log(err);
          return;
        }
        case 500: {
          this.toastr.error('Ha ocurrido un error, contáctenos para más información');
          return throwError(err);
        }
        case 400: {

          if (
            request.url.includes("oauth")
          ) {
            if (request.body.includes("refresh_token")) {
              this.storageService.logout();
              this.storageService.authorizeSubject.next({ 'authorize': false, 'url': '/' });
              const modalRef = this.modalService.open(ModalExpiredSessionComponent, { centered: true, windowClass: 'expired-session' });
            } else {
              this.parseError(err);
            }
          } else {
            this.parseError(err);
          }
          return throwError(err);
        }
        case 503: {
          this.router.navigate(['/mantenimiento']);
          return throwError(err);
        }
        case 0: {
          this.toastr.error('Ha ocurrido un error, no se ha podido establecer la conexión con el servidor', null, {
            timeOut: 24000
          });
          return throwError(err);
        }
        default: {
          this.toastr.error('Ha ocurrido un error, contáctenos para más información');
          return throwError(err);
        }
      }
    }));
  }

  refrechTokenUnauthorize(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (this.refreshTokenInProgress) {
      // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
      // – which means the new token is ready and we can retry the request again
      return this.refreshTokenSubject
        .pipe(
          filter(result => result !== null),
          take(1),
          switchMap(() => next.handle(this.addAuthenticationToken(request)))
        );
    } else {
      this.refreshTokenInProgress = true;

      // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
      this.refreshTokenSubject.next(null);
      // Call auth.refreshAccessToken(this is an Observable that will be returned)
      return this.loginService.refreshAccessToken()
        .pipe(
          switchMap((token: any) => {
            this.refreshTokenInProgress = false;
            this.refreshTokenSubject.next(token);

            return next.handle(this.addAuthenticationToken(request));
          }),
          catchError((err: any) => {
            this.refreshTokenInProgress = false;
            if (
              request.url.includes("oauth")
            ) {
              if (!request.body.includes("refresh_token")) {
                this.parseError(err);
              }
            } else {
              this.parseError(err);
            }
            return throwError(err);
          })

        );
    }

  }

  addAuthenticationToken(request) {
    // Get access token from Local Storage
    const accessToken = this.storageService.getCurrentToken();
    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.storageService.getCurrentToken()}`
      }
    });
  }

  parseError(err: any) {
    if (err.error.modelState) {
      for (const key in err.error.modelState) {
        if (err.error.modelState.hasOwnProperty(key)) {
          for (let i = 0; i < err.error.modelState[key].length; i++) {
            this.toastr.error(err.error.modelState[key][i], null, {
              timeOut: Utils.timeOutError(err.error.modelState[key][i])
            });
          }
        }
      }
    } else if (err.error.message) {
      this.toastr.error(err.error.message !== 'The request is invalid.' ?
        err.error.message : 'Ha ocurrido un error, contáctenos para más información', null, {
        timeOut: err.error.message !== 'The request is invalid.' ? Utils.timeOutError(err.error.message) : 10000
      });
    } else if (err.error.error_description) {
      this.toastr.error(err.error.error_description, null, {
        timeOut: Utils.timeOutError(err.error.error_description)
      });
    } else {
      this.toastr.error('Ha ocurrido un error, contáctenos para más información');
    }
  }
}
