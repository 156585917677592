import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { HttpClient } from '@angular/common/http';
import { SortDirection } from '@app/directives/sortable.directive';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, switchMap, delay } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { PriceModelv2 } from '@app/models/price.model';

interface SearchResult {
  contacts: any[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: SortDirection;
}

function compare(v1, v2) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

function sort(countries: any[], column: string, direction: string): any[] {
  if (direction === '') {
    return countries;
  } else {
    return [...countries].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'ASC' ? res : -res;
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class ContactsService extends SharedService {

  private _search$ = new Subject<void>();
  private _contacts$ = new BehaviorSubject<any[]>([]);
  private _total$ = new BehaviorSubject<number>(0);


  private _state: State = {
    page: 1,
    pageSize: 10,
    sortColumn: '',
    sortDirection: ''
  };

  constructor(
    httpClient: HttpClient,
    private storageService: StorageService
  ) {
    super(httpClient);
    this._search$.pipe(
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
    ).subscribe(result => {
      this._contacts$.next(result.contacts);
      this._total$.next(result.total);
    });

    this._search$.next();
  }

  get contacts$() { return this._contacts$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get state() { return this._state; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set sortColumn(sortColumn: string) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }
  set state(state: State) { this._set(state); }


  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Promise<SearchResult> | Observable<SearchResult> {
    if (this.state.sortColumn !== '') {
      const { sortColumn, sortDirection, pageSize, page } = this._state;
      return new Promise((resolve, reject) => {
        Promise.all([
          this.getPaginatedContacts(pageSize.toString(), page.toString(), sortColumn, sortDirection)
        ]).then(
          (result: any) => {
            const contacts = sort(result[0].contacts, sortColumn, sortDirection);
            const total = result[0].totalCount;
            resolve({ contacts, total });
          }, reject);
      });
    }

    return new Observable();
  }

  getContacts() {
    const url = 'api/TopUp/GetContacts?st=0';
    return this.getItems(url);
  }

  getPaginatedContacts(pageSize: string, pageNumber: string, orderBy: string, order: string) {
    const url = 'api/TopUp/GetContacts';
    return this.getPaginatedItems(url, pageSize, pageNumber, orderBy, order);
  }

  getContactsWithNauta() {
    const url = 'api/TopUp/GetContacts?st=1';
    return this.getItems(url);
  }

  getPriceByService(serviceType: number, offerType = null): Promise<PriceModelv2> {
    if (serviceType == 0) {
      return this.getPrice(0, offerType);
    } else if (serviceType == 1) {
      return this.getPrice(1, offerType);
    } else {
      return this.getPrice(3, offerType);
    }
  }

  getPricesForContacts() {
    const url = 'api/TopUp/GetPrices?st=0&sn=false';
    return this.getItems(url);
  }

  getPricesForContactsWithNauta() {
    const url = 'api/TopUp/GetPrices?st=1&sn=true';
    return this.getItems(url);
  }


  getPrice(serviceType, offerType: number = null): Promise<PriceModelv2> {
    const url = 'api/TopUp/GetPrices';
    const payload = offerType != null ? { serviceType: [serviceType], offerType } : { serviceType: [serviceType] };
    return this.postItmes(url, payload);
  }

  getIZCR() {
    const user = this.storageService.getCurrentUser();
    if (user && (user.isZipCodeRequired == 'true' || user.isZipCodeRequired == 'false')) {
      return user.isZipCodeRequired == 'true' ? Promise.resolve(true) : Promise.resolve(false);
    } else {
      const url = 'api/TopUp/IZCR';
      return this.getItems(url);
    }
  }

  saveContact(contacts: any) {
    const url = 'api/TopUp/SaveContact';
    return this.postItmes(url, contacts);
  }

  deleteContact(id: string) {
    const url = `api/profile/DeleteContact?id=${id}`;
    return this.postItmes(url, {});
  }

  addContact(contact: any) {
    const url = `api/profile/AddContact`;
    return this.postItmes(url, contact);
  }

  updateContact(contact: any) {
    const url = `api/profile/UpdateContact`;
    return this.postItmes(url, contact);
  }
}
