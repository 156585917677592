import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { StorageService } from '@app/services/storage.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

  title = 'Recargas a Cuba 🥇 recibe tu DESCUENTO AHORA con Cubatel';
  description = 'Más de【200 mil usuarios】envían recargas a Cuba con Cubatel ✅ Obtén RECOMPENSAS en efectivo por recargas Cubacel y Nauta ➤ ¡Pincha Aquí! y recibe DESCUENTOS HOY';

  constructor(private metaService: Meta, private router: Router, private storageService: StorageService) {
  }

  ngOnInit() {
    this.metaService.updateTag({ name: 'description', content: this.description });
    this.metaService.updateTag({ name: 'keywords', content: '' });
    this.metaService.updateTag({ name: 'robots', content: 'noindex,nofollow'  });
    this.metaService.updateTag({ property: 'og:title', content: this.title });
    this.metaService.updateTag({ property: 'og:description', content: this.description });
    this.metaService.updateTag({ property: 'og:url', content: `${environment.host}${this.router.url}` });
    if (this.router.url.includes('/login')) {
      this.storageService.logout();
      this.storageService.authorizeSubject.next({ 'authorize': false, 'url': '/' });
    }
  }

}
