<div class="modal-header pt-4 pb-3">
  <h5 class="modal-title ">VERIFICACIÓN DE ACCESO</h5>
</div>


<div class="modal-body py-0">

  <div *ngIf="towFactorEnabled" class="form-group">
    <p>Por favor, ingrese el código de verificación enviado a su correo electrónico para acceder a su cuenta:</p>
    <input aria-label="Código de verificación" [formControl]="verificationCode" class="d-block w-100 form-control"
      type="text" placeholder="Código de verificación">
    <div *ngIf="verificationCode.errors && !verificationCode.pristine">
      <div *ngIf="verificationCode.errors.required" class="text-danger error"> El código de verificación es
        obligatorio
      </div>
      <div *ngIf="verificationCode.errors.maxlength || verificationCode.errors.minlength" class="text-danger error">
        El código de verificación debe tener 6 dígitos
      </div>
    </div>
  </div>
  <div class="form-group">
    <p *ngIf="!towFactorEnabled">Hemos actualizado los Términos y Condiciones de Cubatel<br>para ofrecerte un mejor servicio. Para ver los
      cambios,<br> pulse aquí >> <a target="_blank" [routerLink]="['/terminos']"
        routerLinkActive="router-link-active">Términos y Condiciones</a></p>
    <div class="custom-control custom-checkbox text-left">
      <input type="checkbox" [formControl]="accept" class="custom-control-input" id="terminos">
      <label class="custom-control-label" for="terminos">Al seleccionar esta casilla de verificación, he revisado y
        acepto los <a [routerLink]="['/terminos']" target="_blank">Términos y Condiciones</a></label>
    </div>
  </div>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary banner-button modal-banner-button secondary" data-dismiss="modal"
    (click)="onCancel()">CANCELAR</button>
  <button *ngIf="!towFactorEnabled" [disabled]="accept.invalid" type="button"
    class="btn btn-primary banner-button modal-banner-button" (click)="onOk()">ENTENDIDO</button>
  <button *ngIf="towFactorEnabled"
    [disabled]="accept.invalid && (verificationCode.invalid || verificationCode.pristine)" type="button"
    class="btn btn-primary banner-button modal-banner-button" (click)="onOk()">ENTENDIDO</button>

</div>
