
import * as fromLocation from '../locations/state/locations-state';
import * as fromShoppingCart from '../products/state/shopping-cart/cart-state';
import * as fromSearch from '../products/state/product-search/search-state';
import * as fromContacts from '@app/marketplace/checkout/state/contacts/contacts-state';
import * as fromCategory from '@app/marketplace/categories/state/category-tree/category-tree-state';
import * as formProductDetails from '@app/marketplace/products/state/product-details/details-state';
export const LAYOUT_FEATURE_KEY = 'layout-feature';

export interface AppStorageState {
  location: fromLocation.State;
  search: fromSearch.State;
  basket: fromShoppingCart.State;
  contacts: fromContacts.State;
  categoryTree: fromCategory.State;
  product_details: formProductDetails.State;
}
