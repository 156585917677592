import {environment} from '@env/environment';

export interface ISeoData {
  sectionId: string;
  hasBanner: boolean;
  container?: ISeoDataContainer;
  categoryCode: string;
  title: string;
  h1: string;
  description: string;
  keywords: string;
  faqs?: ISeoFaq[];
}

export interface ISeoDataContainer {
  subtitle?: ISeoDataTextInfo;
  style?: any;
}

export interface ISeoDataTextInfo {
  text?: string;
  style?: any;
}

export interface ISeoFaq {
  ask: string;
  answer: string;
}
export const _seoContent: ISeoData[] = [
  { sectionId: 'comida', hasBanner: true, container: { subtitle: {text: 'Carnes, vegetales, lácteos y mucho más', style: {color: '#073C33'}}, style: {background: 'linear-gradient(70deg, #48c2ae 0%, #46bda9 48%, #3ba694 74%, #087664 100%)'}}, categoryCode: environment.production ? '7d17887f' : '6aac62f8', h1: 'Envío de comida a Cuba', title: 'Cubatel Market | Agencia de envíos de comida a Cuba', description: 'Descubre cómo enviar combos de comida para Cuba. Incluye cajas de pollo para cuba, lácteos y huevo, carne de cerdo y res, y combos de comida para La Habana.', keywords: 'combos de comida para cuba, envío de comida a cuba, envíos de alimentos a cuba, combos de comida para cuba desde miami, combos de comida para la habana cuba, combo cajas de pollo para cuba, envío de combos a cuba, envío de combos de comida a cuba, envío de combos a cuba desde estados unidos, enviar combos de comida para cuba, envios de paquetes de comida a cuba, agencia de envios de comida a cuba, agencias de envíos de alimentos a cuba', faqs:
      [
        {ask: '¿Cómo enviar comida a Cuba desde Estados Unidos?', answer: 'Desde Cubatel podrás realizar envíos de comida a Cuba. Solo selecciona tu producto, introduce los datos del destinatario, y realiza el pago.'},
        {ask: '¿Cómo enviar combos de comida a Cuba?', answer: 'Para enviar combos de comida para Cuba, entra en nuestro Market, en la categoría “Combos”, y luego en la subcategoría “Combos de alimentos”. Ahí encontrarás todas las ofertas que necesitas.'},
        {ask: '¿La comida que se compra en el market de Cubatel se importa desde Estados Unidos?', answer: 'Muchos de nuestros productos, como el pollo, las carnes y conservas, son importados directamente desde Estados Unidos. Otros alimentos los producen directamente nuestros partners en Cuba.'}
      ]},
  { sectionId: 'productos-envio-cuba', hasBanner: true, container: { subtitle: {text: 'Entregamos a domicilio tus compras online: televisores, refrigeradores, freezer y más', style: {color: '#073C33'}}, style: {background: 'linear-gradient(80deg, #6699ff 0%, #386acc 100%)'}}, categoryCode: environment.production ? 'd4b923a0' : '26538c14',  h1: 'Envíos a Cuba', title: 'Cubatel Market | Tu tienda de envíos a Cuba', description: '¡Somos el Amazon cubano! Descubre nuestras tiendas online para comprar en Cuba desde el exterior. Entregamos a domicilio en todas las provincias cubanas.', keywords: 'tiendas online para envíos a cuba, amazon cubano, amazon en cuba, tiendas de envíos a cuba, tienda de envíos a cuba, envío a cuba online, tiendas online con envío a cuba, compras online en cuba desde el exterior, tiendas online en cuba para comprar desde el exterior, tiendas de envíos a cuba online, tiendas virtuales en cuba para comprar desde el exterior, compras online para cuba desde el exterior, tiendas online para comprar en cuba desde el exterior, compras desde el exterior para cuba'},
  { sectionId: 'aseo', hasBanner: true, container: { subtitle: {text: 'Pasta de dientes, Desodorantes, Champú', style: {color: '#073C33'}}, style: {background: 'linear-gradient(80deg, #6699ff 0%, #386acc 100%)'}}, categoryCode: environment.production ? '142c1117' : '23692d94', h1: 'Los mejores productos de aseo para Cuba', title: 'Cubatel Market | Envíos de productos de aseo personal a Cuba', description: 'Envía combos de aseo para Cuba desde Miami. Descubre los combos de aseo personal para Cuba que incluye jabones, pasta de dientes, champú, desodorante, y mucho más.', keywords: 'combos de aseo personal para cuba, envíos de aseo a cuba, productos de aseo para cuba, comprar aseo para cuba, envío de aseo a cuba, combos de aseo para cuba desde miami', faqs:
      [
        {ask: '¿Dónde comprar aseo personal para enviar a Cuba?', answer: 'En el Market, entra en la categoría Aseo y Belleza y encontrarás productos de aseo para Cuba. También en la sección Combos, hallarás ofertas de combos de aseo personal para Cuba.'},
        {ask: '¿Qué tiendas envían combos de aseo a Cuba?', answer: 'En Cubatel Market podrás comprar aseo para Cuba al mejor precio y con una calidad insuperable. ¡Revisa nuestras tiendas ahora!'}
    ]},
  { sectionId: 'piezas-motos-carros', hasBanner: true, container: { subtitle: {text: 'Gomas, kit de motos y repuestos para motos eléctricas', style: {color: '#073C33'}}, style: {background: 'linear-gradient(81deg, #898df4 0%, #4f54c7 100%)'}}, categoryCode: environment.production ? 'c5f145b1' : '392a242d', h1: 'Tienda de piezas de carros y motos para Cuba', title: 'Cubatel Market | De todo en piezas de motos y carros para Cuba', description: 'Encuentra en Cubatel Market piezas de carros y motos para Cuba en Miami. Descubre nuestra venta de piezas de motos eléctricas en Cuba y disfruta de nuestra variedad de precios.', keywords: 'piezas de carros para cuba, piezas de carros para cuba en miami, piezas de carro para cuba, tienda de piezas de carros para cuba, piezas de moto para cuba, venta de piezas de motos eléctricas en cuba, tienda de piezas de moto para cuba, piezas de motos para cuba', faqs:
      [
        {ask: '¿Qué marcas de autos se venden en Cuba?', answer: 'Las marcas más comunes de autos en Cuba son Lada, Moscovich, Fiat y marcas americanas como Chevrolet y Buick. En nuestro mercado encontrarás varias  tiendas de piezas de carros y motos para Cuba.'},
        {ask: '¿Qué tipos de partes y piezas de motos puedo encontrar?', answer: 'Encuentra piezas de moto para Cuba en nuestro Market. Descubre kits de motos, gomas, baterías y mucho más.'}
      ]},
  { sectionId: 'bebida', hasBanner: true, container: { subtitle: {text: 'Maltas, Cervezas, Rones, Refrescos', style: {color: '#073C33'}}, style: {background: 'linear-gradient(81deg, #f48989 0%, #d54c4c 100%)'}}, categoryCode: environment.production ? '9b88d1b0' : 'b3683dea', h1: 'Envío de bebidas para Cuba', title: 'Cubatel Market | Para refrescar: envío de bebidas para Cuba', description: 'Compra de bebidas para Cuba. Incluye cajas de refresco, malta, cerveza, y botellas de ron, vinos y más. Las mejores marcas, al mejor precio.', keywords: 'envio de bebidas para cuba, compra de bebidas para cuba', faqs: [
      {ask: '¿Se puede comprar cerveza online para Cuba?', answer: 'Sí. El envío de bebidas para Cuba es posible. Revisa en la categoría de Bebidas y encuentra tus marcas de cerveza favoritas.' }
    ]},
  { sectionId: 'combos', hasBanner: true, container: { subtitle: {text: '¡Combinación perfecta de cantidad y precio!', style: {color: '#073C33'}}, style: {background: 'linear-gradient(81deg, #898df4 0%, #4f54c7 100%)'}}, categoryCode: environment.production ? '65587dcc' : '89a67d47', h1: 'Envío de combos a Cuba', title: 'Cubatel Market | Envío de combos a Cuba hasta la puerta de tu casa', description: 'Haz tu envío de combos a Cuba desde Estados Unidos y cualquier lugar del mundo. Incluye combos de aseo personal, cajas de pollo para Cuba, y combos de alimentos para La Habana.', keywords: 'combos de comida para cuba, combos de comida para cuba desde miami, combos de comida para la habana cuba, combo cajas de pollo para cuba, envío de combos a cuba, envío de combos de comida a cuba, envío de combos a cuba desde estados unidos, enviar combos de comida para cuba, combos de aseo para cuba desde miami, combos de aseo personal para cuba', faqs: [
      {ask: '¿Cuánto cuesta un envío de paquetes a Cuba?', answer: 'El costo del envío depende del producto que compres y el lugar de destino. Siempre antes de pagar tendrás la información exacta del costo.'},
      {ask: '¿Sólo se pueden comprar combos de comida en el Market de Cubatel?', answer: 'Además de combos de comida para Cuba, también podrás enviar combos de aseo personal y combos mixtos.'}
    ]},
  { sectionId: 'medicamentos', hasBanner: true, container: { subtitle: {text: 'Arma el botiquín de tu gente con nuestros productos', style: {color: '#073C33'}}, style: {background: 'linear-gradient(81deg, #FFA166 0%, #C6530A 100%)'}}, categoryCode: environment.production ? '20621fc4' : '38fc9788', h1: 'Comprar medicamentos online para Cuba', title: 'Cubatel Market | Comprar medicamentos online para Cuba fácil y rápido', description: 'Descubre la forma más fácil y rápida de comprar medicamentos online para Cuba. Incluye vitaminas para niños y ancianos, antibióticos, analgésicos, y todo lo que necesita tu familia para armar su botiquín en Cuba.', keywords: 'comprar medicamentos online en cuba, comprar medicamentos online para cuba', faqs: [
      {ask: '¿Cómo se puede mandar medicamentos a Cuba?', answer: 'En el Market, entra en la categoría Farmacia y Salud. Ahí podrás comprar medicamentos online para Cuba.'},
      {ask: '¿Puedo traer medicamentos sin receta a Cuba?', answer: 'Sí. En el Market puedes comprar medicamentos online en Cuba sin receta médica.'}
    ]},
  { sectionId: 'ferreteria', hasBanner: true, container: { subtitle: {text: 'Herramientas eléctricas, Candados y cerraduras, Pilas de agua, Materiales de construcción', style: {color: '#073C33'}}, style: {background: '#6699ff'}}, categoryCode: environment.production ? '828bd7a3' : '87df9557', h1: 'Compra productos de ferretería para Cuba', title: 'Cubatel Market | Compra los mejores productos en ferretería para Cuba', description: 'Encuentra los mejores productos en ferretería para Cuba. Desde herramientas eléctricas a candados y cerraduras, pilas de agua, protector de voltaje, silicona, y más.', keywords: 'ferretería para Cuba, ferreteria en Cuba', faqs: [
      {ask: '¿Se puede comprar cemento online para Cuba?', answer: 'Sí. En la categoría de Ferretería encontrarás este y otros productos similares.'}
    ]},
  { sectionId: 'electrodomesticos-hogar', hasBanner: true, container: { subtitle: {text: 'Lavadoras, televisores, neveras, microondas. ¡Calidad segura!', style: {color: '#073C33'}}, style: {background: 'linear-gradient(70deg, #48c2ae 0%, #46bda9 48%, #3ba694 74%, #087664 100%)'}}, categoryCode: environment.production ? 'a114e0d3' : 'fe0ff41e', h1: 'Compra de electrodomésticos para Cuba', title: 'Cubatel Market | Renueva tu hogar: envío de electrodomésticos a Cuba', description: 'Encuentra los mejores electrodomésticos para Cuba en un solo lugar. Descubre nuestra variedad de televisores, refrigeradores, microondas, y lavadoras y secadoras para Cuba.', keywords: 'electrodomésticos para cuba, lavadoras para cuba, envío de electrodomésticos a cuba, envíos de electrodomésticos a cuba, electrodomésticos a cuba, compra de electrodomésticos para cuba, envíos de equipos electrodomésticos a cuba, lavadoras y secadoras para cuba, electrodomésticos para cuba online, envío de equipos electrodomésticos a cuba', faqs: [
      {ask: '¿Cuánto cuesta el envío de un TV a Cuba?', answer: 'El envío de electrodomésticos a Cuba depende de las dimensiones del producto y de la provincia de entrega. Siempre sabrás el costo antes de pagar.'},
      {ask: '¿Cómo enviar neveras a Cuba?', answer: 'Para enviar neveras a Cuba, revisa en el Market las categorías Envíos y Hogar. Ahí encontrarás variedad de ofertas y precios en los envíos de equipos electrodomésticos a Cuba.'},
      {ask: '¿Cuánto cuesta enviar una lavadora por paquetería a Cuba?', answer: 'El envío de lavadoras y secadoras para Cuba depende del tamaño del producto y la provincia de entregas. Siempre sabrás el costo antes de pagar.'}
    ]},
  { sectionId: 'celulares-computadoras', hasBanner: true, container: { subtitle: {text: 'Las marcas más reconocidas a un precio imbatible', style: {color: '#073C33'}}, style: {background: 'linear-gradient(81deg, #f48989 0%, #d54c4c 100%)'}}, categoryCode: environment.production ? 'ef41fd8f' : 'f927b595', h1: 'Envío de celulares y computadoras a Cuba', title: 'Cubatel Market | Envío de celulares y computadoras a Cuba', description: 'La mejor tecnología a precios baratos | Descubre cómo enviar celular a Cuba rápido y seguro | Entrega de celulares en Cuba con las mejores marcas: Samsung, Xiaomi, Infinix Hot, Tecno Spark, entre otras.', keywords: 'enviar celular a cuba, entrega de celulares en cuba, comprar telefono para cuba, envíos de teléfonos celulares a cuba, envío de celulares a cuba', faqs: [
      {ask: '¿Cuánto vale el envío de un celular a Cuba?', answer: 'El coste del envío de celulares a Cuba depende de la provincia de entrega. Siempre sabrás el costo antes de pagar.'},
      {ask: '¿Cuántos celulares puedo llevar a Cuba en 2023?', answer: 'A través del Market de Cubatel, podrás enviar celulares a Cuba en la cantidad que desees. '},
      {ask: '¿Cuántas computadoras laptop se puede llevar a Cuba?', answer: 'A través del Market de Cubatel, podrás enviar computadoras a Cuba en la cantidad que desees.'},
      {ask: '¿Cómo se puede enviar un celular a Cuba?', answer: 'Para envíos de celulares y computadoras a Cuba, revisa nuestras categorías Envíos y Tecnología. Encontrarás variedad de marcas y precios y podrás elegir la mejor para ti.'}
    ]},
  { sectionId: 'comida-restaurante', hasBanner: true, container: { subtitle: {text: 'De la cocina de los restaurantes más famosos a la mesa de tu casa en Cuba.', style: {color: '#073C33'}}, style: {background: 'linear-gradient(80deg, #6699ff 0%, #386acc 100%)'}}, categoryCode: environment.production ? '3e6ba65b' : '66cf408e', h1: 'Compras de comida de restaurante en Cuba', title: 'Cubatel Market | Compras de comida de restaurante en Cuba', description: 'Descubre las mejores ofertas de buffet de cumpleaños para Cuba. Cajitas de dulces, cake, croquetas, panes, cervezas, refrescos, maltas. ¡Variedad y exquisitez al mejor precio!', keywords: 'buffet de cumpleaños en cuba, buffet de cumpleaños para cuba, buffet para cumpleaños cuba', faqs: [
      {ask: '¿Qué hacen los cubanos para los cumpleaños?', answer: 'En las fiestas, a los cubanos les encanta compartir con amigos y familiares. Encuentra en el Market buffet de cumpleaños para Cuba en la sección Restaurante.'},
      {ask: '¿Cuál es la comida favorita de los cubanos?', answer: 'A los cubanos les encanta la comida criolla: el congrí, la carne de cerdo, las yucas, viandas fritas y ensalada. Encuentra en el Market compras de comida de restaurante en Cuba y alegra a tu gente con una sorpresa.'}
    ]},
  { sectionId: 'ofertas-descuentos-tienda-envios-cuba', hasBanner: true, container: { subtitle: {text: 'Encuentra las mejores rebajas en compras a Cuba', style: {color: '#073C33'}}, style: {background: 'linear-gradient(81deg, #f48989 0%, #d54c4c 100%)'}}, categoryCode: environment.production ? '930bd7' : 'aee9dac1', h1: 'Descuentos en compras a Cuba', title: 'Cubatel Market | Rebajas | Ofertas de envíos a Cuba', description: 'Los mejores descuentos en compras a Cuba. Descubre nuestras ofertas de combos en La Habana, envío de comida a Cuba, combos de aseo personal, piezas de carros y motos para Cuba en Miami y mucho más.', keywords: 'ofertas de combos en la habana, combos a cuba ofertas, descuentos en compras a cuba, rebajas en compras a cuba'},
  { sectionId: 'amazon-para-cuba', hasBanner: false, categoryCode: '', h1: '', title: 'Cubatel | Compra en Amazon para Cuba', description: 'Accede a millones de productos en Amazon con entrega a domicilio en Cuba.', keywords: 'Amazon para Cuba, compra Amazon en Cuba, Amazon online Cuba'},
];
