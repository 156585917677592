import { ProductProviderEnum } from '@app/marketplace/core/enum/productProvider';
import { productsFeature } from './../product-details/details-selectors';
import {createAction, props} from '@ngrx/store';

export const KEYPRESS_IN_SEARCH= '[SEARCH] Kepress in search';

export const SEARCH_PRODUCTS = '[SEARCH] Search products';
export const SEARCH_PRODUCTS_SUCCESS = '[SEARCH] Search products success';
export const SEARCH_PRODUCTS_FAILURE = '[SEARCH] Search products failure';

export const SEARCH_PRODUCTS_ERROR_ = '[SEARCH] Search products error';
export const CANCEL_SEARCH_REQUEST = '[SEARCH] Cancel search request';
export const DELETE_SEARCH_TERM= '[SEARCH] Delete search term';
export const SET_PROVIDER= '[SEARCH] Set Provider';

export const KeyPressInSearch= createAction(
  KEYPRESS_IN_SEARCH,
  props<{term:string}>()
);
export const SearchProducts= createAction(SEARCH_PRODUCTS);
export const SearchProductsSuccess= createAction(
  SEARCH_PRODUCTS_SUCCESS,
  props<{term:string}>()
);
export const SearchProductsFailure = createAction(SEARCH_PRODUCTS_FAILURE);
export const SearchProductsError = createAction(SEARCH_PRODUCTS_ERROR_);
export const CancelSearchRequest = createAction(CANCEL_SEARCH_REQUEST);
export const DeleteSearchTerm = createAction(DELETE_SEARCH_TERM);
export const SetProvider= createAction(
  SET_PROVIDER,
  props<{provider:ProductProviderEnum}>()
);
