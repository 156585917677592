import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modal-terms-and-conditionals',
  templateUrl: './modal-terms-and-conditionals.component.html',
  styleUrls: ['./modal-terms-and-conditionals.component.css'],
})
export class ModalTermsAndConditionalsComponent implements OnInit {

  @Input()
  towFactorEnabled: boolean;
  verificationCode: FormControl;
  accept: FormControl;

  constructor(
    private activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {

    this.accept = new FormControl(true, [Validators.requiredTrue]);
    this.verificationCode = new FormControl('', [Validators.required, Validators.maxLength(6), Validators.minLength(6)]);
  }

  ngOnInit() {
  }

  onOk() {
    if (this.accept.invalid) {
      this.toastr.warning('Se recomienda que acepte los términos y condiciones para seguir usando la aplicación');
      return;
    }
    if (this.towFactorEnabled && this.verificationCode.invalid) {
      return;
    }

    if (this.towFactorEnabled) {
      this.accept.value ? this.activeModal.close({ code: this.verificationCode.value, accept: true }) : this.activeModal.close(false);
    } else {
      this.accept.value ? this.activeModal.close(true) : this.activeModal.close(false);
    }

  }

  onCancel() {
    this.activeModal.close(false);
  }

}
