import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAuthComponent } from './auth-modal.component';

@NgModule({
  declarations: [
    ModalAuthComponent
  ],
  imports: [
    CommonModule,
  ]
})
export class AuthModalModule {
}
