import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Store} from '@ngrx/store';

@Injectable()
export class FirebaseProductsGuard implements CanActivate {

  constructor(private router: Router, private store: Store<any>) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      if (route.queryParams.stu_provider) {
        console.log(route.queryParams.stu_provider);
        if (route.queryParams.stu_provider == '1') {
          this.router.navigate([`/market/productos-compras-cuba-online`], {queryParams: {stu_provider: 1}});
        } else {
          this.router.navigate([`/market/productos-compras-cuba-online`], {queryParams: {stu_provider: 0}});
        }
      } else {
        this.router.navigate([`/market/productos-compras-cuba-online`]);
      }
    return false;
  }
}
