import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { VerificationByEmailModel } from '@app/models/verification-by-email.model';

@Injectable({
  providedIn: 'root'
})
export class CallsService extends SharedService {

  constructor(
    private http: HttpClient,
  ) {
    super(http);
  }

  verifyByEmail(verificationModel: VerificationByEmailModel) {
    const url = 'api/Call/Verify';
    return this.postItmes(url, verificationModel);
  }

  verifyCancel(token: any) {
    const url = 'api/Call/Cancel';
    return this.postItmes(url, token);
  }

  claimPhoneNumber(model: any, access_token: string) {
    const url = 'api/Call/ClaimPhoneNumber';
    if (access_token) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Authorization': `Bearer ${access_token}`, 'Accept-Language': 'es', 'Stu-App-Version': '2.0.0' })
      };
      return this.postItmesWithCustomHeader(url, model, httpOptions);
    } else {
      return this.postItmes(url, model);
    }


  }
}
