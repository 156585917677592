import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CallsService } from '@app/services/calls.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal-claim-phone-number',
  templateUrl: './modal-claim-phone-number.component.html',
  styleUrls: ['./modal-claim-phone-number.component.css']
})
export class ModalClaimPhoneNumberComponent implements OnInit {

  @Input()
  phoneNumber: string;
  @Input()
  access_token: string;
  @Output()
  response = new EventEmitter<any>();

  model: any;

  loadingChange$: Observable<boolean>;

  constructor(
    private activeModal: NgbActiveModal,
    private callService: CallsService,
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.loadingChange$ = this.callService.loadingChange$;
    this.model = {
      action: 2,
      phoneNumber: this.phoneNumber,
      claim: false
    };
  }

  claimPhoneNumber() {
    this.model.claim = true;
    this.sendRequest();
  }

  cancel() {
    this.model.claim = false;
    this.sendRequest();
  }

  sendRequest() {
    this.callService.claimPhoneNumber(this.model, this.access_token)
      .then(response => {
        this.response.emit({ completed: true });
      }).catch(error => {
        this.response.emit({ completed: true });
      });
  }
}
