import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UrlService } from '../services/url.service';
import { TrackingComponent } from '../shared/components/tracking/tracking.component';
import { FingerprintService } from '../services/fingerprint.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DeepLinkType } from '../models/deep-link.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent extends TrackingComponent implements OnInit {

  @Input()
  flashOfferEnabled: Observable<boolean>;

  @Input()
  mainPages: boolean;

  currentYear: number;

  isRemittancePage = false;
  DeepLinkType = DeepLinkType;

  constructor(private urlService: UrlService,
    private fingerprintService: FingerprintService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(urlService, fingerprintService, null, route, 'stu_btn_recharge_offer');
    this.currentYear = new Date().getFullYear();
  }

  ngOnInit() {

    this.urlService.currentUrl
      .subscribe(url => {
        this.isRemittancePage = url.includes('envio-dinero-cuba') || url.includes('privacidad');
      });
  }

  goToLink(url: string, external = false) {
    if (!external) {
      this.router.navigate([url]);
    } else {
      window.open(url);
    }
  }
}
