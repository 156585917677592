/**
 * Created by Elaine Lopez on 4/23/2019.
 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@app/models/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { SharedService } from './shared.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {LocationModel} from '@app/marketplace/shared/_models/location.model';
import { Store } from '@ngrx/store';
import * as authActions from '@app/marketplace/auth/state/auth-actions'
import * as cartActions from "@app/marketplace/products/state/shopping-cart/cart-action";
import {PromoCodeModel} from '@app/models/promo-code.model';
declare let gapi: any;
declare let google: any;
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Accept-Language': 'es',
    'Stu-App-Version': '2.0.0',
  }),
};
const baseUrl = environment.baseUrl;
@Injectable({
  providedIn: 'root',
})
export class StorageService extends SharedService {
  private localStorageService;
  private currentUserSubject: BehaviorSubject<User>;
  currentUser: Observable<User>;
  private promoCodeSubject: BehaviorSubject<PromoCodeModel>;
  promoCode: Observable<PromoCodeModel>;
  authorizeSubject: BehaviorSubject<{}>;
  authorize: Observable<{}>;
  landingPageSubject: BehaviorSubject<boolean>;
  landingPage: Observable<boolean>;
  loadLoginSubject: BehaviorSubject<boolean>;
  loadLogin: Observable<boolean>;
  loadExternalLoginSubject: BehaviorSubject<boolean>;
  loadExternalLogin$: Observable<boolean>;

  constructor(private router: Router, private http: HttpClient,private store: Store<any>) {
    super(http);
    this.localStorageService = localStorage;
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.promoCodeSubject = new BehaviorSubject<PromoCodeModel>(
      JSON.parse(localStorage.getItem('stu_code'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.promoCode = this.promoCodeSubject.asObservable();
    this.authorizeSubject = new BehaviorSubject<{}>({
      authorize: true,
      url: '',
    });
    this.authorize = this.authorizeSubject.asObservable();
    this.loadLoginSubject = new BehaviorSubject<boolean>(false);
    this.loadLogin = this.loadLoginSubject.asObservable();
    this.loadExternalLoginSubject = new BehaviorSubject<boolean>(false);
    this.loadExternalLogin$ = this.loadExternalLoginSubject.asObservable();
  }
  setCurrentUser(user: User): void {
    this.localStorageService.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
    this.store.dispatch(authActions.LogInSuccess({ payload: user}));
  }

  setPromoCode(code: PromoCodeModel): void {
    this.localStorageService.setItem('stu_code', JSON.stringify(code));
    this.promoCodeSubject.next(code);
  }

  setAmazonWizard(): void {
    this.localStorageService.setItem('amz', JSON.stringify({exp: Date.now()}));
  }

  setCurrentLocation(location: LocationModel, key: string): void {
    this.localStorageService.setItem(key, JSON.stringify(location));
  }

  setCurrentUser1(user: User): void {
    this.localStorageService.setItem('currentUser', JSON.stringify(user));
  }
  loadSessionData(): User {
    const userStr = this.localStorageService.getItem('currentUser');
    return userStr ? <User>JSON.parse(userStr) : null;
  }
  removeCurrentUser(): void {
    this.localStorageService.removeItem('currentUser');
    this.localStorageService.removeItem('basket');
  }

  removePromoCode(): void {
    this.localStorageService.removeItem('stu_code');
    this.promoCodeSubject.next(null);
  }
  removeAmazonWizard(): void {
    this.localStorageService.removeItem('amz');
  }
  removeCurrentLocation(): void {
    this.localStorageService.removeItem('currentLocation');
  }
  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }
  getPromoCode(): PromoCodeModel {
    return this.promoCodeSubject.value;
  }
  getAmazonWizard(): any {
    return JSON.parse(localStorage.getItem('amz'));
  }
  getCurrentLocation(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }
  isAuthenticated(): boolean {
    return this.getCurrentToken() != null ? true : false;
  }
  getCurrentToken(): string {
    const user = this.getCurrentUser();
    return user != null && user.access_token ? user.access_token : null;
  }
  logout(): void {
    this.store.dispatch(authActions.Logout());
    this.store.dispatch(cartActions.ClearCart());
    this.logoutWithoutRedirect();
    this.router.navigate(['']);
  }
  logoutWithoutRedirect() {
    if (this.isAuthenticated()) {
      this.signoutGoogleAuth();
      const url = 'api/accounts/signout';
      this.postItmes(url, {});
    }
    this.removeCurrentUser();
    this.currentUserSubject.next(null);
  }
  private signoutGoogleAuth() {
    if (this.getCurrentUser().provider == 'Google' && typeof google === 'function') {
        google.accounts.id.disableAutoSelect();
    }
  }

  getSavedState(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  setSavedState(model: any, key: string) {
    this.localStorageService.setItem(key, JSON.stringify(model));
  }
}
