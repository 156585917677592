import {NgModule, Optional, Self} from '@angular/core';
import {RouterStateSerializer, StoreRouterConnectingModule, routerReducer} from '@ngrx/router-store';
import {ActionReducer, ActionReducerMap, MetaReducer, StoreModule} from '@ngrx/store';
import {MergedRouterStateSerializer} from './merged-route-serialzer';
import {Router} from '@angular/router';
import * as fromAuthRouter from '../auth/state/auth-reducers';
import * as fromAuth from '../auth/auth.state';
import { MergedRouteReducerState } from './merged-route';
import { localStorageSync } from 'ngrx-store-localstorage';

export interface RootState {
  router: MergedRouteReducerState;
  auth: fromAuth.State;
}

export const reducers: ActionReducerMap<RootState> = {
  router: routerReducer,
  auth:fromAuthRouter.reducer
};

/* export const layoutReducer: ActionReducerMap<fromStorage.AppStorageState> = {
  location: locationReducers.reducer, //key: location
  shoppingcart: shoppingCartReducers.reducer //key: shoppingcart
};
 */
export function authStorageSyncReducer(reducer: ActionReducer<RootState>) {
  return localStorageSync({
    keys: ['auth'],
    storageKeySerializer: (key: string) =>{ return `${key}`},
    rehydrate: true,
    storage: window.localStorage,
  })(reducer);
}
export const metaReducers: MetaReducer<RootState>[] = typeof window !== 'undefined' ? [authStorageSyncReducer] : [];

export const routerStateConfig = {stateKey: 'root'};
@NgModule({
  imports: [
     StoreModule.forFeature(routerStateConfig.stateKey, reducers,
      {
        metaReducers,
         initialState: {
          router: {
            state: {
              url: typeof window !== 'undefined' ? window.location.pathname : '',
              params: {},
              queryParams: {},
              data:{}
            },
            navigationId: 0
          }
        }
      }),
    StoreRouterConnectingModule.forRoot(routerStateConfig),
  ],
  exports: [
    StoreModule,
    StoreRouterConnectingModule
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: MergedRouterStateSerializer,
    }
  ]
})
export class NgrxRouterStoreModule {
  constructor(@Self() @Optional() router: Router) {
  }
}

