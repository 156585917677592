<div class="d-flex justify-content-end"><span style="z-index: 9999" class="font-times cursor-pointer" (click)="onCancel()">&times;</span></div>
<div class="modal-body p-0 position-relative d-lg-flex align-items-center" [class.two-device]="!device">
  <div class="d-flex flex-column flex-lg-row pb-0 pl-lg-5">
    <div class="d-flex flex-column align-items-center align-items-lg-start" style="z-index: 1">
      <p class="text-center text-lg-left mb-2 font-weight-bold Poppins"><span class="bold">Descarga<br> nuestra App</span></p>
      <span class="text-center text-lg-left OpenSans w-75">Recargas, envío de comida, remesa y más desde el mismo sitio</span>
      <div class="d-flex flex-column flex-lg-row">
        <a *ngIf="(device && device == 'Android') || !device" class="google-play my-3" target="_blank" (click)="onCancel()" rel="noreferrer"
           [href]="device ? googlePlayPath : 'https://play.google.com/store/apps/details?id=com.cubatel.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'">
          <img class="d-flex d-lg-none" width="203" height="59" alt="Google Play" src="assets/apps/btn-googleplay.svg" />
          <img class="d-none d-lg-flex" width="186" height="53" alt="Google Play" src="assets/apps/btn-googleplay.svg" />
        </a>
        <a *ngIf="(device && device == 'iOS') || !device" class="app-store stu-app-store my-lg-3 ml-lg-2" [class.my-3]="device && device == 'iOS'" target="_blank" (click)="onCancel()"
           [href]="device ? appleStorePath : 'https://itunes.apple.com/us/app/cubatel/id1300396049'" rel="noreferrer">
          <img class="d-flex d-lg-none" width="203" height="59" alt="App Store" src="assets/apps/btn-appstore.svg" />
          <img class="d-none d-lg-flex" width="186" height="53" alt="App Store" src="assets/apps/btn-appstore.svg" />
        </a>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <picture class="d-block d-lg-none position-absolute" style="bottom:-7px;z-index: 0">
        <source srcset="assets/apps/panflo-mobile-1.png" type="image/png">
        <source srcset="assets/apps/panflo-mobile-1.png" type="image/png">
        <img width="420" alt="Descargar App" src="assets/apps/panflo-mobile-1.png">
      </picture>
      <picture class="d-none d-lg-block position-absolute" style="bottom: -22px;right: -50px;">
        <source srcset="assets/apps/panfilo-mobile-md-1.png" type="image/png">
        <source srcset="assets/apps/panfilo-mobile-md-1.png" type="image/png">
        <img width="482" alt="Descargar App" src="assets/apps/panfilo-mobile-md-1.png">
      </picture>
    </div>
    <!--<p class="text-center mb-3"><span class="bold">DESCÁRGALA GRATIS EN:</span></p>
    <div class="d-flex d-sm-none flex-column align-items-center">
      <a *ngIf="(device && device == 'Android') || !device" class="google-play mb-3 mt-3" target="_blank" (click)="onCancel()" rel="noreferrer"
        [href]="device ? googlePlayPath : 'https://play.google.com/store/apps/details?id=com.cubatel.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'">
        <picture>
          <source srcset="assets/apps/app-google-play.webp" type="image/webp">
          <source srcset="assets/apps/app-google-play.png" type="image/png">
          <img width="150" alt="Google Play" src="assets/apps/app-google-play.png">
        </picture>

      </a>
      <a *ngIf="(device && device == 'iOS') || !device" class="app-store stu-app-store" target="_blank" (click)="onCancel()"
        [href]="device ? appleStorePath : 'https://itunes.apple.com/us/app/cubatel/id1300396049'" rel="noreferrer">
        <picture>
          <source srcset="assets/apps/app-store.webp" type="image/webp">
          <source srcset="assets/apps/app-store.png" type="image/png">
          <img width="150" src="assets/apps/app-store.png" alt="App Store">
        </picture>

      </a>
    </div>
    <div class="d-none d-sm-flex flex-row justify-content-around align-items-center mt-3">
      <a *ngIf="(device && device == 'Android') || !device" class="google-play" target="_blank" (click)="onCancel()" rel="noreferrer"
        [href]="device ? googlePlayPath : 'https://play.google.com/store/apps/details?id=com.cubatel.android&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'">
        <picture>
          <source srcset="assets/apps/app-google-play.webp" type="image/webp">
          <source srcset="assets/apps/app-google-play.png" type="image/png">
          <img width="180" alt="Google Play" src="assets/apps/app-google-play.png">
        </picture>

      </a>
      <a *ngIf="(device && device == 'iOS') || !device" class="app-store stu-app-store" target="_blank" (click)="onCancel()"
        [href]="device ? appleStorePath : 'https://itunes.apple.com/us/app/cubatel/id1300396049'" rel="noreferrer">
        <picture>
          <source srcset="assets/apps/app-store.webp" type="image/webp">
          <source srcset="assets/apps/app-store.png" type="image/png">
          <img width="180" src="assets/apps/app-store.png" alt="App Store">
        </picture>
      </a>
    </div>-->
  </div>
</div>
<div *ngIf="device" class="modal-footer justify-content-center d-md-none">
  <a class="btn btn-primary banner-button modal-banner-button" target="_blank" (click)="onCancel()"
     [href]="device == 'iOS' ? appleStorePath : googlePlayPath" rel="noreferrer">
    Descargar
  </a>
</div>
