import { Injectable, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RechargeCheckoutModel } from '../models/recharge-checkout.model';
import { VerificationModel } from '@app/models/verification.model';
import { RememberCardPaymentModel } from "@app/models/remembered-card-payment.model";
import { SharedService } from './shared.service';
import { SortDirection } from '@app/directives/sortable.directive';
import { debounceTime, switchMap, delay } from 'rxjs/operators';
import { VerificationByEmailModel } from '@app/models/verification-by-email.model';
import { PaymentMethod } from '@app/models/payment-methods.model';
import { RechargeCashbackModel } from '@app/models/recharge-cashback.model';
import { StuTokenTransactionModel, StuPaypalTransactionModel, StuTransactionBaseModel, StuTransactionModel, StuVerificationModel } from '@app/models/checkout/stu-transaction-model';
import { StuCouponModel } from '@app/models/reward/stu-coupon-model';
import { HomeRechargeModel } from '../models/recharge/home-recharge.model';

interface SearchResult {
  recharges: any[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  sortColumn: string;
  sortDirection: SortDirection;
}

function compare(v1, v2) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

function sort(countries: any[], column: string, direction: string): any[] {
  if (direction === '') {
    return countries;
  } else {
    return [...countries].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'ASC' ? res : -res;
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class RechargeService extends SharedService {

  private _search$ = new Subject<void>();
  private _recharges$ = new BehaviorSubject<any[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  priceSubject = new BehaviorSubject<any>({});
  rechargeSubject = new BehaviorSubject<HomeRechargeModel>(new HomeRechargeModel({}));

  private _state: State = {
    page: 1,
    pageSize: 10,
    sortColumn: '',
    sortDirection: ''
  };

  constructor(
    private http: HttpClient,
  ) {
    super(http);
    this._search$.pipe(
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
    ).subscribe(result => {
      this._recharges$.next(result.recharges);
      this._total$.next(result.total);
    });

    this._search$.next();
  }

  get contacts$() { return this._recharges$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get state() { return this._state; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set sortColumn(sortColumn: string) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }
  set state(state: State) { this._set(state); }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Promise<SearchResult> | Observable<SearchResult> {
    if (this.state.sortColumn !== '') {
      const { sortColumn, sortDirection, pageSize, page } = this._state;
      return new Promise((resolve, reject) => {
        Promise.all([
          this.getPaginatedTransactions(pageSize.toString(), page.toString(), sortColumn, sortDirection)
        ]).then(
          (result: any) => {
            const recharges = sort(result[0].transactions, sortColumn, sortDirection);
            const total = result[0].totalCount;
            resolve({ recharges, total });
          }, reject);
      });
    }

    return new Observable();
  }

  getCustomerProfile() {
    const url = 'api/Recharge/GetCustomerProfile';
    return this.getItems(url);
  }


  card(rechargeAccountModel: StuTransactionModel) {
    const url = 'api/Transaction/Card';
    return this.postItmes(url, rechargeAccountModel);
  }

  validate3D(model: any) {
    const url = 'api/Transaction/PayerV2AuthenticationCallback';
    return this.postItmes(url, model);
  }

  getPriceDtoByRechargeAmountAndServiceType(serviceType: string, amount: number, promoType: number) {
    const url = 'api/TopUp/GetPriceDtoByRechargeAmountAndServiceType';
    return this.postItmesWithoutLoading(url, { serviceType: serviceType, amount: amount, promoType: promoType });
  }

  paypal(rechargeAccountModel: StuPaypalTransactionModel) {
    const url = 'api/Transaction/Paypal';
    return this.postItmes(url, rechargeAccountModel);
  }

  cashback(rechargeCashback: StuTransactionBaseModel) {
    const url = 'api/Transaction/Cashback';
    return this.postItmes(url, rechargeCashback);
  }

  verifyAmount(verificationModel: StuVerificationModel) {
    const url = 'api/Transaction/VerifyAmount';
    return this.postItmes(url, verificationModel);
  }

  verifyByEmail(verificationModel: VerificationByEmailModel) {
    const url = 'api/Recharge/Verify';
    return this.postItmes(url, verificationModel);
  }

  verifyCancel(token: any) {
    const url = 'api/Recharge/Cancel';
    return this.postItmes(url, token);
  }

  deleteRechargeTransaction(ids: number[]) {
    const url = 'api/Profile/DeleteTransactions';
    return this.postItmes(url, {ids: ids});
  }

  disablePaymentToken(id: number) {

    const paymentTokenId = {
      Id: id
    };
    const url = 'api/Recharge/DisablePaymentToken';
    return this.postItmes(url, paymentTokenId);
  }

  addPaymentToken(paymentMethod: PaymentMethod) {
    const url = 'api/Recharge/AddPaymentToken';
    return this.postItmes(url, paymentMethod);
  }

  setDefaultPaymentToken(id: number) {
    const paymentTokenId = {
      Id: id
    };
    const url = 'api/Recharge/SetDefaultPaymentToken';
    return this.postItmes(url, paymentTokenId);
  }

  token(rememberCardPaymentModel: StuTokenTransactionModel) {
    const url = 'api/Transaction/Token';
    return this.postItmes(url, rememberCardPaymentModel);
  }

  getPaginatedTransactions(pageSize: string, pageNumber: string, orderBy: string, order: string) {
    const url = 'api/TopUp/GetTransactions';
    return this.getPaginatedItems(url, pageSize, pageNumber, orderBy, order);
  }

  getCoupon(coupon: StuCouponModel) {
    const url = 'api/Reward/ApplyCoupon';
    return this.postItmes(url, coupon);
  }


}
