import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class StuSelectServiceService {

    itemsSubject = new BehaviorSubject<any>({});
    items = this.itemsSubject.asObservable();

    selectedItemSubject = new BehaviorSubject<any>({});
    selectedItem = this.selectedItemSubject.asObservable();

    setItems(item: any) {
        this.itemsSubject.next(item);
    }

    setSelectedItem(item: any) {
        this.selectedItemSubject.next(item);
    }
}