import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbNavModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { QuestionCashbackModalModule } from '../modal-question-cashback/model-question-cashback.module';
import { ModalRewardsComponent } from './modal-rewards.component';

@NgModule({
  declarations: [ModalRewardsComponent],
  imports: [
    CommonModule,
    NgbModalModule,
    NgbNavModule,
    QuestionCashbackModalModule
  ],
  exports: [
      ModalRewardsComponent
  ]
})
export class RewardsModalModule { }
