/**
 * Created by Elaine Lopez on 3/10/2019.
 */

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, share } from 'rxjs/operators';
import { UserRegister } from '../models/user-register.model';
import { StorageService } from '@app/services/storage.service';
import { SharedService } from './shared.service';
import { ContactFormModel } from '@app/models/contact.form.model';
import { ResetPasswordModel } from '@app/models/reset-password.model';
import { VerifyOptModel } from '@app/models/verify-opt.model';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept-Language': 'es', 'Stu-App-Version': '2.0.0' })
};
const baseUrl = environment.baseUrl;
const client_id = environment.client_id;

@Injectable({
  providedIn: 'root',
})
export class LoginService extends SharedService {

  private localLoginUrl: string;
  private externalLoginUrl: string;

  loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  constructor(private http: HttpClient,
    public storageService: StorageService

  ) {
    super(http);
    this.localLoginUrl = baseUrl + 'oauth/token';
    this.externalLoginUrl = baseUrl + 'api/accounts/ExternalLoginMobile';
  }

  login(username, password) {

    return this.http.post<any>(this.localLoginUrl, 'grant_type=password&username=' + username + '&password=' + password + '&client_id=' + client_id,
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Accept-Language': 'es', 'Stu-App-Version': '2.0.0' } });
  }

  logout() {
    this.storageService.logout();
  }


  register(userRegister: UserRegister) {
    return this.http.post<any>(baseUrl + 'api/accounts/create', userRegister, httpOptions);
  }

  validEmailAfterRegister(email: any) {
    return this.http.post<any>(baseUrl + 'api/accounts/validateEmail', email, httpOptions);
  }


  externalProviderAuth(data) {
    return this.http.post<any>(this.externalLoginUrl, data, httpOptions);
  }

  getUserInfo() {
    const url = 'api/TopUp/GetUserInfo';
    return this.getItems(url);
  }

  updateProfile(profile: FormData) {
    const url = 'api/profile/UpdateProfile';
    return this.postItmes(url, profile);
  }

  verifyPhoneNumber(verify: any) {
    const url = 'api/accounts/VerifyPhoneNumber';
    return this.postItmes(url, verify);
  }

  phoneVerification(model: any, access_token: string) {
    const url = 'api/Accounts/PhoneVerification';
    if (access_token) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Authorization': `Bearer ${access_token}`, 'Accept-Language': 'es', 'Stu-App-Version': '2.0.0' })
      };
      return this.postItmesWithCustomHeader(url, model, httpOptions);
    }
    return this.postItmes(url, model);
  }

  sendContactForm(contact: ContactFormModel) {
    const url = 'api/TopUp/SendContactForm';
    return this.postItmes(url, contact);
  }

  forgotPassword(email: string) {
    const url = 'api/accounts/forgotpassword';
    return this.postItmes(url, { email: email });
  }

  resetPassword(resetPasswordModel: ResetPasswordModel) {
    const url = 'api/accounts/resetPassword';
    return this.postItmes(url, resetPasswordModel);
  }

  refreshAccessToken(): Observable<any> {
    const user: any = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post<any>(this.localLoginUrl, 'grant_type=refresh_token&refresh_token=' + user.refresh_token + '&client_id=' + client_id,
      { headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Accept-Language': 'es', 'Stu-App-Version': '2.0.0' } })
      .pipe(share(), map(token => {
        if (token) {
          const userAuth: any = this.storageService.getCurrentUser();
          const userRefresh = token;
          userRefresh.emailNotificationsAllowed = userAuth.emailNotificationsAllowed;
          userRefresh.phoneVerified = userAuth.phoneVerified;
          userRefresh.preRechargeMode = userAuth.preRechargeMode;
          userRefresh.smsNotificationsAllowed = userAuth.smsNotificationsAllowed;
          userRefresh.termsAndConditionsApproved = userAuth.termsAndConditionsApproved;
          userRefresh.userFirstName = userAuth.userFirstName;
          userRefresh.userLastName = userAuth.userLastName;
          userRefresh.userName = userAuth.userName;
          userRefresh.userPhone = userAuth.userPhone;
          userRefresh.userLevel = userAuth.userLevel;
          this.storageService.setCurrentUser(userRefresh);
        }
        return token;
      }));

  }

  landingPageLogin(transactionId: string, userId: string) {
    const url = `api/accounts/LandingPageLogin?transactionId=${transactionId}&userId=${userId}`;
    return this.getItems(url);
  }

  isMaintenanceMode() {
    const url = 'api/TopUp/GetAppStatus';
    return this.getItems(url);
  }

  towFactorAuth(item: VerifyOptModel, access_token: string) {
    const url = 'api/accounts/VerifyOtp';
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${access_token}`, 'Accept-Language': 'es', 'Stu-App-Version': '2.0.0' })
    };
    return this.postItmesWithCustomHeader(url, item, httpOptions);
  }

}

