<div class="modal-header p-0" style="border-bottom: transparent">
  <button tabindex="-1" type="button" class="close position-absolute" (click)="activeModal.close(true)" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-0">
  <ng-container #authentication></ng-container>
  <div *ngIf="loading | async" class="backdrop" style="border-radius: 8px">
    <div class="text-center">
      <picture>
        <source srcset="assets/loading.webp" type="image/webp" />
        <source srcset="assets/loading.gif" type="image/gif" />
        <img
          style="height: 70px; width: 70px"
          src="assets/loading.gif"
          alt="Cargando..."
        />
      </picture>
    </div>
  </div>
</div>
