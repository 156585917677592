import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';

const baseUrl = environment.baseUrl;
const httpOptions = {
  headers: new HttpHeaders({ 'Accept-Language': 'es', 'Stu-App-Version': '2.0.0' })
};

export class SharedService {

  loadingSubject = new BehaviorSubject(false);
  loading$ = this.loadingSubject.asObservable();

  loadingChangeSubject = new BehaviorSubject(false);
  loadingChange$ = this.loadingChangeSubject.asObservable();

  language = 'es';

  /**
   * Constructor
   *
   */
  constructor(protected _httpClient: HttpClient) {
    httpOptions.headers.set('Accept-Language', this.language);
  }

  /**
   * Get items
   *
   */
  getItems(url: string): Promise<any> {
    this.loadingSubject.next(true);
    return new Promise((resolve, reject) => {
      this._httpClient.get(baseUrl + url, httpOptions)
        .subscribe((response: any) => {
          this.loadingSubject.next(false);
          resolve(response);
        }, (reason: any) => {
          this.loadingSubject.next(false);
          reject(reason);
        });
    });
  }

  /**
   * Get paginated items
   *
   */
  getPaginatedItems(url: string, pageSize: string, pageNumber: string, orderBy: string, order: string): Promise<any> {
    this.loadingSubject.next(true);
    return new Promise((resolve, reject) => {
      this._httpClient.get(baseUrl + url + `/${pageSize}/${pageNumber}/${orderBy}/${order}`, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
          this.loadingSubject.next(false);
        }, (reason: any) => {
          this.loadingSubject.next(false);
          reject(reason);
        });
    });
  }

  /**
   * Post items
   *
   */
  postItmes(url: string, items: any): Promise<any> {
    this.loadingChangeSubject.next(true);
    return new Promise((resolve, reject) => {
      this._httpClient.post(baseUrl + url, items, httpOptions)
        .subscribe((response: any) => {
          this.loadingChangeSubject.next(false);
          resolve(response);
        }, (reason: any) => {
          this.loadingChangeSubject.next(false);
          reject(reason);
        });
    });
  }

  postItmesWithoutLoading(url: string, items: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(baseUrl + url, items, httpOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, (reason: any) => {
          reject(reason);
        });
    });
  }

  postItemsWithCustomUrl(url: string, items: any) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(url, items)
        .subscribe((response: any) => {
          resolve(response);
        }, (reason: any) => {
          reject(reason);
        });
    });
  }

  postItmesWithCustomHeader(url: string, items: any, httpOptions: any): Promise<any> {
    this.loadingChangeSubject.next(true);
    return new Promise((resolve, reject) => {
      this._httpClient.post(baseUrl + url, items, httpOptions)
        .subscribe((response: any) => {
          this.loadingChangeSubject.next(false);
          resolve(response);
        }, (reason: any) => {
          this.loadingChangeSubject.next(false);
          reject(reason);
        });
    });
  }
 //--------------------------------------------------------------------------------
 post<T>(endpoint: string, data: any): Observable<T> {
  //const url =authenticated? `${apiUrl}${endpoint}`:`${baseUrl}${endpoint}`;
  const url =`${baseUrl}${endpoint}`;
  this.loadingChangeSubject.next(true);
  return this._httpClient.post<T>(url, data, httpOptions).pipe(
    map((response: any) => {
      if(!response) return {};
      if (typeof response === "string") {return JSON.parse(response);}
      else { return response;}
    }),
    finalize(() => this.loadingChangeSubject.next(false)),
  );
}
get<T>(endpoint: string,authenticated:Boolean=false): Observable<T> {
  //const url =authenticated? `${apiUrl}${endpoint}`:`${baseUrl}${endpoint}`;
  const url =`${baseUrl}${endpoint}`;
   this.loadingSubject.next(true);
    return this._httpClient.get<T>(url, httpOptions).pipe(
      map((response: any) => {
        if (typeof response === "string") {return JSON.parse(response);}
        else { return response;}
      }),
      finalize(() => this.loadingSubject.next(false)),
    );
  }
}
