import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CustomValidator } from '@app/helpers/validators';
import { LoginService } from '@app/services/login.service';
import { ToastrService } from 'ngx-toastr';
import { ModalClaimPhoneNumberComponent } from '../modal-claim-phone-number/modal-claim-phone-number.component';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { reduce } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal-phone-verification-code',
  templateUrl: './modal-phone-verification-code.component.html',
  styleUrls: ['./modal-phone-verification-code.component.css']
})
export class ModalPhoneVerificationCodeComponent implements OnInit, OnDestroy {

  @Input()
  phoneNumber: string;
  @Input()
  visiblePhone: string;
  @Input()
  access_token: string;

  @Output()
  response = new EventEmitter<any>();

  @Output()
  goBack = new EventEmitter();


  model: any;
  waitTime = 0;
  interval: any;

  codeControl: FormControl;

  loadingChange$: Observable<boolean>;

  verificationCode = true;
  claimNumberPhone = false;

  constructor(
    private loginService: LoginService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal
  ) {
    this.codeControl = new FormControl('', [Validators.required, CustomValidator.codeVerificationValidator]);
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  ngOnInit() {
    this.loadingChange$ = this.loginService.loadingChange$;
    this.model = {
      action: 1,
      attempt: 1,
      phoneNumber: this.phoneNumber,
      code: ''
    };
  }

  sendCode() {
    if (!this.codeControl.invalid && !this.codeControl.pristine) {
      this.model.code = this.codeControl.value;
      this.model.phoneNumber = this.visiblePhone;
      this.model.action = 1;
      this.loginService.phoneVerification(this.model, this.access_token)
        .then(response => {
          if (response.attempt == 0) {
            this.model.attempt = 1;
            this.waitTime = response.waitTime;
            this.countDown();
            this.toastr.error(response.errorTitle + ' ' + response.errorMessage);
          } else {
            this.model.attempt = response.attempt + 1;
            if (response.success) {
              if (!response.claimable) {
                this.response.emit({ completed: true });
              } else {
                this.verificationCode = false;
                this.claimNumberPhone = true;
              }
            } else {
              this.toastr.error(response.errorTitle + ' ' + response.errorMessage);
            }
          }
        }).catch(error => {
          this.response.emit({ error: true });
        });
    } else {
      this.toastr.error('El código no es válido');

    }

  }

  countDown() {
    this.interval = setInterval(() => {
      if (this.waitTime != 0) {
        this.waitTime--;
      } else {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  sendNewCode() {
    this.model.action = 0;
    this.model.phoneNumber = this.phoneNumber;
    this.loginService.phoneVerification(this.model, this.access_token)
      .then(response => {
        if (response.attempt == 0) {
          this.model.attempt = 1
          this.waitTime = response.waitTime;
          this.countDown();
          this.toastr.error(response.errorTitle + ' ' + response.errorMessage);
        } else {
          this.model.attempt = response.attempt + 1;
          if (!response.success) {
            this.toastr.error(response.errorTitle + ' ' + response.errorMessage);
          }
        }
      }).catch(error => {
        this.response.emit({ error: true });
      });
  }

  convertToDate() {
    return new Date(this.waitTime * 1000).toISOString().substr(11, 8);
  }

  claimNumberPhoneResponse(response: any) {
    if (!response.error) {
      if (response.completed) {
        this.response.emit({ completed: true });
      } else {
        this.response.emit({ completed: false });
      }
    } else {
      this.response.emit({ error: true });
    }
  }

  verificationPhone() {
    this.goBack.emit();
  }

}
