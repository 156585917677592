import { enableProdMode, NgModule, Injectable, ErrorHandler, SystemJsNgModuleLoader, NgModuleFactoryLoader, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginService } from './services/login.service';
import { HomeComponent } from './home/home.component';
import { MenuComponent } from './menu/menu.component';
import { JwtInterceptor, ErrorInterceptor } from '@app/helpers';
import { AuthGuard } from '@app/guards/auth.guard';
import { StorageService } from '@app/services/storage.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DataService } from '@app/services/data.service';
import { UrlService } from '@app/services/url.service';
import { CommonModule } from '@angular/common';
import { NgProgressModule } from '@ngx-progressbar/core';
import { PromotionsService } from './services/promotions.service';
import { ContactsService } from './services/contacts.service';
import { NotFoundComponent } from './not-found/not-found.component';
import { RechargeService } from './services/recharge.service';
import { HtmlContentService } from './services/html-content.service';
import { DownLoadAppSeoModule } from './shared/components/download-app/download-app.module';
import { ModalExpiredSessionComponent } from './shared/components/modal-expired-session/modal-expired-session.component';
import { RewardsModalModule } from './shared/components/modal-rewards/modal-rewards.module';
import { AuthenticationService } from './services/authentication.service';
import { FooterModule } from '@app/footer/footer.module';
import { ModalAuthComponent } from './menu/auth-modal/auth-modal.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { ForgotPasswordComponent } from './shared/components/forgot-password/forgot-password.component';
import { AuthModalModule } from './menu/auth-modal/auth-modal.module';
import { RedirectWithParamsGuard } from './guards/redirect-with-params.guard';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { LayoutModule } from './marketplace/layout/layout.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {NgrxRouterStoreModule} from './marketplace/route/ngrx-router.module';
import {SignatureInterceptor} from '@app/marketplace/core/interceptors/signature.interceptor';
import {TreeviewModule} from 'ngx-treeview';
import {FirebaseProductGuard} from '@app/guards/firebase-product.guard';
import {FirebaseProductsGuard} from '@app/guards/firebase-products.guard';
import {SharedModule} from '@app/shared/shared.module';
enableProdMode();
@NgModule({
    imports: [
        CommonModule,
        BrowserModule.withServerTransition({appId: 'cubatel-website'}),
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        TreeviewModule.forRoot(),
        NgProgressModule,
        DownLoadAppSeoModule,
        RewardsModalModule,
        FooterModule,
        AuthModalModule,
        NgxIntlTelInputModule,
        SocialLoginModule,
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        NgrxRouterStoreModule,
        StoreDevtoolsModule.instrument({maxAge: 25, logOnly: !isDevMode()}),
        SharedModule
    ],
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    NotFoundComponent,
    ModalExpiredSessionComponent,
    DateAgoPipe
  ],
  providers: [
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SignatureInterceptor, multi: true},
    { provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('971082983006874')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    RedirectWithParamsGuard,
    FirebaseProductGuard,
    FirebaseProductsGuard
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalExpiredSessionComponent,
  ]
})
export class AppModule {
}
