export class HomeRechargeModel {
  priceId: number;
  phoneNumber: string;
  nautaAccount: string;

  constructor(model?) {
    model = model;
    this.priceId = model.priceId || '';
    this.phoneNumber = model.phoneNumber || '';
    this.nautaAccount = model.nautaAccount || '';
  }
}
