import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';
import { DeepLinkModel } from '@app/models/deep-link.model';
import { BehaviorSubject } from 'rxjs';
import { SharedService } from './shared.service';


@Injectable({
  providedIn: 'root'
})
export class UrlService extends SharedService {

  private onMenuCloseSubject = new BehaviorSubject<boolean>(false);
  private urlResource = new BehaviorSubject<string>('');
  public currentUrl = this.urlResource.asObservable();
  public onMenuClose = this.onMenuCloseSubject.asObservable();
  public footer = new EventEmitter<any>();
  public recarga_a_cuba = new EventEmitter<any>();
  public fragmentSubject = new BehaviorSubject<string>('');
  public fragment$ = this.fragmentSubject.asObservable();

  constructor(httpCLient: HttpClient) { super(httpCLient); }

  changeUrl(url: string) {
    this.urlResource.next(url);
  }

  changeMenu(close: boolean) {
    this.onMenuCloseSubject.next(close);
  }

  callToAction(model: DeepLinkModel) {
    const url = 'api/Notification/Ads';
    return this.postItmes(url, model);
  }

}
